import { MapContext } from "@/contexts/map-context"
import { Adjust, ExpandMore, Home, RadioButtonChecked, RadioButtonUnchecked, TripOrigin } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Input, Stack, Typography, styled } from "@mui/material";
import { useContext, useState } from "react";
// import {useDistanceRings} from '@/hooks/use-distance-rings';

export const DistanceRings = styled(Accordion)({
    // backgroundColor: 'white',
    // color: 'black',
    // border: '1px solid #cecece',
    backgroundColor: '#05233b',
    color: 'white',
    border: '1px solid #103d6a',
    // position: 'absolute',
    // top: '20px',
    // right: '10px',
    borderRadius: '5px',
    width:"300px",
    '&:hover': {
        // backgroundColor: '#efefef',
        cursor: 'pointer',
        border: '1px solid #dedede',
    },
});

export const InsertExportRingsField = styled(Stack)({
    justifyContent:'space-between',
    alignItems: "cemter",
});

export const DistanceRingsPanel = () => {
    const { center, gotoLocation, distanceRings, setDistanceRings } = useContext(MapContext);
    const [expanded, setExpanded] = useState<boolean>(false);
    // const {ring1, ring2, ring3, setRing1, setRing2, setRing3} = useDistanceRings();

    const onChangeRing1 = (value:number) => {
       setDistanceRings([value, distanceRings[1], distanceRings[2]])
    }
    const onChangeRing2 = (value:number) => {
        setDistanceRings([distanceRings[0], value, distanceRings[2]])
    }
    const onChangeRing3 = (value:number) => {
        setDistanceRings([distanceRings[0], distanceRings[1], value])
    }

    return (
        <DistanceRings onChange={() => setExpanded(!expanded)} expanded={expanded}>
            <AccordionSummary expandIcon={<ExpandMore sx={{color:'white'}}/>} sx={{display:'flex', flexDirection: 'row', alignItems:'center', justifyContent: "space-between"}}>
            <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px" >
                    <Adjust sx={{width: '20px'}}/> 
                    <Typography sx={{fontSize: '14px', fontWeight:'bold'}}>Distance Rings</Typography>
                </Stack>
                
            </AccordionSummary>
            <AccordionDetails>
                <Typography sx={{color: '#bebebe', fontSize:'14px'}}>Set distance rings</Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px" >
                        {/* <RadioButtonChecked/>  */}
                        <Typography sx={{fontSize:'14px', fontWeight:'bold'}}>Ring 1</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Input sx={{width:"60px", fontSize:'14px', color: 'white'}} type='number' onChange={(e) => onChangeRing1(parseInt(e.target.value))} value={distanceRings[0]}/>
                        <span style={{color: '#bebebe', fontSize:'14px'}}>m</span>
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px" >
                        {/* <TripOrigin/>  */}
                        <Typography sx={{fontSize:'14px', fontWeight:'bold'}}>Ring 2</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Input sx={{width:"60px", fontSize:'14px', color: 'white'}} type='number' onChange={(e) => onChangeRing2(parseInt(e.target.value))} value={distanceRings[1]}/>
                        <span style={{color: '#bebebe', fontSize:'14px'}}>m</span>
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px" >
                        {/* <RadioButtonUnchecked/> */}
                        <Typography sx={{fontSize:'14px', fontWeight:'bold'}}> Ring 3</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Input sx={{width:"60px", fontSize:'14px', color: 'white'}} type='number' onChange={(e) => onChangeRing3(parseInt(e.target.value))} value={distanceRings[2]}/>
                        <span style={{color: '#bebebe', fontSize:'14px'}}>m</span>
                    </Stack>
                </Stack>
                {/* <Button variant='contained' fullWidth sx={{marginTop: '20px'}}>Confirm</Button> */}
            </AccordionDetails>
        </DistanceRings>
    )
}