import { ReactNode, createContext, useEffect, useRef, useState } from "react";
import * as mqtt from "mqtt";


interface IInterfaceContext {
    sideMenuOpen: boolean,
    setSideMenuOpen: (sideMenuOpen: boolean) => void,
};

export const InterfaceContext = createContext<IInterfaceContext>({
    sideMenuOpen: true,
    setSideMenuOpen: (_: boolean) => {},
});

interface IContextProps {
    children?: ReactNode,
}


const InterfaceContextProvider = ({ children }: IContextProps) => {
    
    const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(true);
   
    return (
        <InterfaceContext.Provider value={{ sideMenuOpen, setSideMenuOpen }}>
            {children}
        </InterfaceContext.Provider>
    )
}

export default InterfaceContextProvider;