import { styled } from "@mui/material";
import { loadModules } from "esri-loader";
import { useContext, useEffect, useState } from "react";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import GroupLayer from "@arcgis/core/layers/GroupLayer.js";
import Draw from "@arcgis/core/views/draw/Draw";
import Map from "@arcgis/core/Map.js";
import MapView from "@arcgis/core/views/MapView.js";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer.js";

import { MapContext } from "@/contexts/map-context";
import { Vehicle } from "@/domain-objects/vehicle";
import { Personnel } from "@/domain-objects/personnel";
import { FlightsContext } from "@/contexts/flights-context";
import Polyline from "@arcgis/core/geometry/Polyline.js";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer.js";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol.js";
import { GeometryLayersContext } from "@/contexts/geometry-layers-context";
import { InterfaceContext } from "@/contexts/interface-context";
import { EntityTypesContext } from "@/contexts/entity-types-context";
import { useLocation } from "react-router-dom";


// import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol.js";


const MapWrap = styled('div')({
    height: 'calc(130vh)',
    marginBottom: '30vh',
    width: '100vw',
    outline: 'none',
    cursor: 'pointer',
})

// { mapScenes.find(scene => scene.title === currentMapScene)?.objects.map((obj: IMapObject) => obj.render())}


export const MapElement = ({ onLoad, mapProperties, viewProperties, className }:
    { onLoad: (a: any, b: any) => void, mapProperties: any, viewProperties: any, className?: string }) => {
    const [view, setView] = useState<any>(null)
    const [map, setMap] = useState<any>(null);
    const [graphicsLayer, setGraphicsLayer] = useState<any>(null);
    const {setSideMenuOpen} = useContext(InterfaceContext);
    const { entityTypesMap } = useContext(EntityTypesContext);

    const draw = new Draw({
        view: view
    });
    const { isPilotModeEnabled, mapScenes, currentMapScene, setMapScenes, mapRotated, enablePolygon, focusObject, distanceRings } = useContext(MapContext);
    
    const { geometryLayers } = useContext(GeometryLayersContext);
    const [currentGeometryLayers, setCurrentGeometryLayers] = useState<any[]>([]);
    const location = useLocation();
    
    useEffect(() => {
        if (map != null) {
            // Remove old
            for (const layer of currentGeometryLayers) {
                map.remove(layer.obj);
            }

            console.log(geometryLayers)
            // Add new
            for (const layer of geometryLayers) {
                if (layer.isVisible) {
                    map.add(layer.obj);
                }
            }
            setCurrentGeometryLayers([...geometryLayers.filter(el => el.isVisible)]);
            console.log([...currentGeometryLayers.filter(el => el.isVisible)]);
        }
    }, [geometryLayers, map]);

    useEffect(() => {
        if (view) {
            view.constraints.rotationEnabled = !isPilotModeEnabled;
        }
    }, [view, isPilotModeEnabled]);

    useEffect(() => {
        // Create a Map instance
        const mapObj = new Map({
            ...mapProperties,
            showLabels: false,
        });

        // Create a MapView instance (for 2D viewing) and reference the map instance
        const viewObj = new MapView({
            map: mapObj,
            container: 'map-wrap',
            spatialReference: {
                wkid: 3857,
            },
            constraints: {
                rotationEnabled: true
              },
            zoom: 12,
            // center: [152.96545481727637, -26.335788270863723],
            center: [21.8550442, 47.0746822],
            ...viewProperties
        });

        const url = URL.createObjectURL(new Blob([JSON.stringify(require('../../assets/geojson/salonta.json'))], {
            type: "application/json"
            }));

        mapObj.add(new GeoJSONLayer({ 
                           url,
                           renderer: new SimpleRenderer({
                            symbol : new SimpleFillSymbol({
                                color : [4, 255, 0, 0.6], 
                                outline : { 
                                    color : [3, 124, 0, 0.6],
                                    width : 0.7
                                }
                            })
                        })
                    }))

        
        // viewObj.graphics.add(graphLayer);
        viewObj.when(() => {
            setGraphicsLayer(viewObj.graphics);
            onLoad(mapObj, viewObj);
            
//             const geojson = { 
//                 "type": "FeatureCollection",
//                 "features": [{
//                     "type": "Feature",
//                     "properties": {
//                         color: 'red'
//                     }
// ,                    "geometry": {
//                         "type": "Polygon", 
//                         "coordinates": [[
//                           [0,0],
//                           [44,20],
//                           [11,20],
//                           [0,0]
//                         ]]
//                     }
//                 }]
//             };
              
//             const url = URL.createObjectURL(new Blob([JSON.stringify(geojson)], {
//                 type: "application/json"
//               }));

//             mapObj.add(new GeoJSONLayer({ 
//                url,
//                renderer: new SimpleRenderer({
//                 symbol : new SimpleFillSymbol({
//                     color : [255,0,0,0.2], 
//                     outline : { 
//                         color : "white",
//                         width : 0.7
//                     }
//                 })
//             })
//             //    url: "https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/all_month.geojson"
//             }))


            viewObj.on("click", (event: any) => {
                viewObj.hitTest(event)
                    .then((response: any) => {
                        // do something with the result graphic
                        if (response.results.length > 0) {
                            var graphic = response.results[0].graphic;
                            if (graphic?.attributes?.onClick) {
                                graphic.attributes.onClick();
                                setSideMenuOpen(true); 
                            }
                        } else {
                            focusObject(null);
                            
                        }
                    });
            });

            setMap(mapObj);
            setView(viewObj);
        });
    }, [])

    // useEffect(() => {
    //     if (enablePolygon) {
    //         enableCreatePolygon(draw, view);
    //     }
    // }, [enablePolygon])

    // useEffect(() => {
        // if (map != null) {
        //     const vehicle1 = new Vehicle(
        //         '123',
        //         'SKYY123',
        //         'quad', 
        //         { heading: 45, speed: 20, position: { latitude: 44.787197, longitude: 20.457273, altitude: 10 } }, 
        //         new Date().getTime(),
        //         (obj)=>focusObject(obj), 
        //         { isVisible: true },
        //         distanceRings,
        //         view,
        //         graphicsLayer,
        //     );
        //     // const person1 = new Personnel(
        //     //     '123', 
        //     //     'quad', 
        //     //     { heading: 45, speed: 2, position: { latitude: 44.787198, longitude: 20.457273, altitude: 10 } }, 
        //     //     (obj)=>focusObject(obj), 
        //     //     {}
        //     // );
        //     const vehicle2 = new Vehicle(
        //         '124', 
        //         'SKYY124',
        //         'heli', 
        //         { heading: 45, speed: 20, position: { latitude: 44.787198, longitude: 20.457273, altitude: 10 } }, 
        //         new Date().getTime(),
        //         (obj)=>focusObject(obj), 
        //         { isVisible: true },
        //         distanceRings,
        //         view,
        //         graphicsLayer,
        //     );
        //     const currentScene = mapScenes.find(ms => ms.title === currentMapScene);
        //     // currentScene?.objects.push( person1 );
        //     setTimeout(() => {
        //         const currentScene = mapScenes.find(ms => ms.title === currentMapScene);
        //         currentScene?.objects.push(
        //             vehicle1, 
        //             vehicle2,
        //         )

        //         // setInterval(() => {
        //         //     vehicle1.update(
        //         //         new Date().toISOString(),
        //         //         { 
        //         //         ...vehicle1.attitude, 
        //         //         speed: Math.random() * 42,
        //         //         heading: (vehicle1.attitude.heading + 2 - 2 * Math.round(Math.random())) % 360,
        //         //         position: { 
        //         //         ...vehicle1.attitude.position,
        //         //         latitude: vehicle1.attitude.position.latitude + 0.000001,
        //         //         longitude: vehicle1.attitude.position.longitude + 0.000001,
        //         //     }}, { isVisible: true })

        //         //     vehicle2.update(
        //         //         new Date().toISOString(),
        //         //         { 
        //         //         ...vehicle2.attitude,
        //         //         speed: Math.random() * 42,
        //         //         heading: (vehicle2.attitude.heading + 2 - 2 * Math.round(Math.random())) % 360,
        //         //         position: { 
        //         //         ...vehicle2.attitude.position,
        //         //         latitude: vehicle2.attitude.position.latitude - 0.000001,
        //         //         longitude: vehicle2.attitude.position.longitude + 0.000001,
        //         //     }}, { isVisible: true })
        //         // }, 1000)
        //     })
        //     setMapScenes([...mapScenes]);
            


    //     }
    // }, [map]);

    const { flights } = useContext(FlightsContext);
    useEffect(() => {
        if (map != null ) {
            const currentScene = mapScenes.find(ms => ms.title === currentMapScene);
            for (const flight of flights) {
                if (flight.obj == null) {
                    flight.obj = new Vehicle(flight.callsign, flight.callsign, flight.entity_type, {
                        position: {
                            latitude: flight.state.latitude,
                            longitude: flight.state.longitude,
                            altitude: flight.state.altitude_geoid,
                        },
                        speed: flight.state.speed,
                        heading: flight.state.heading,
                    },
                    flight.timestamp,
                        (obj) => focusObject(obj),
                        { proximityWarning: flight.proximityWarning}, distanceRings, view, view.graphics)
                    currentScene?.objects.push(flight.obj);

                    // Workaround, need to render at least once so that the aircraft has its lastLayers set to something
                    flight.obj.render()
                } else {
                        flight.obj.update(flight.timestamp, {
                                position: {
                                    latitude: flight.state.latitude,
                                    longitude: flight.state.longitude,
                                    altitude: flight.state.altitude_geoid,
                                },
                                speed: flight.state.speed,
                                heading: flight.state.heading,
                            },
                            {
                                source: flight.report_source_type,
                                proximityWarning: flight.proximityWarning,
                                isVisible:  location.pathname === '/' ? (entityTypesMap[flight.entity_type] || { isVisible: true }).isVisible : false,
                            }
                        )
                    
                }
            }
        }
    }, [flights, map])

    useEffect(() => {
        if (graphicsLayer != null) {
            const currentScene = mapScenes.find(ms => ms.title === currentMapScene);
            currentScene?.objects.forEach(obj => obj.render());
        }
    }, [mapScenes, currentMapScene, graphicsLayer, mapRotated])

    return (
        <MapWrap id="map-wrap" className={className} />
    )
}