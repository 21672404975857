import { MapContext } from "@/contexts/map-context"
import { ExpandMore, FlightTakeoff, Home, ToggleOff, ToggleOn } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Paper, Stack, Typography, styled } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";

export const PilotPanel = styled(Accordion)({
    backgroundColor: '#05233b',
    color: 'white',
    border: '1px solid #103d6a',
    // position: 'absolute',
    // top: '250px',
    // left: '10px',
    width:"300px",
    borderRadius: '5px',
    '&:hover': {
        // backgroundColor: '#efefef',
        cursor: 'pointer',
        border: '1px solid #dedede',
    },
});

interface ITelemetryItemProps {
    itemLabel: string,
    itemValue: any
}


export const TelemetryItem: React.FC<ITelemetryItemProps> = ({itemLabel, itemValue}: ITelemetryItemProps) => {
    return(
        <Stack direction='row' alignItems='center' justifyContent={'space-between'}>
            <Typography sx={{fontWeight:'bold', fontSize: "14px"}}>{itemLabel}</Typography>
            <Typography sx={{fontSize: "14px"}}>{itemValue}</Typography>
        </Stack>
    )
}


export const TelemetryPanel = () => {
    const { center, gotoLocation, focusedObject } = useContext(MapContext);
    const [expanded, setExpanded] = useState<boolean>(true);
    const {isPilotModeEnabled, enablePilotMode, disablePilotMode} = useContext(MapContext);
    const [update, setUpdate] = useState<number>(0);
    const interval = useRef<any>(null);

    useEffect(() => {
        if (interval.current == null) {
            interval.current = setInterval(() => setUpdate(Math.random()), 500);
            return () => clearInterval(interval.current)
        }
    }, [])
   
    return (
        <PilotPanel onChange={() => setExpanded(!expanded)} expanded={expanded}>
             <AccordionSummary expandIcon={<ExpandMore sx={{color: 'white'}}/>} >
                <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px">
                    <FlightTakeoff sx={{width: '20px'}}/> 
                    <Typography sx={{fontSize: '14px' , fontWeight:'bold'}}>Tracked object telemetry</Typography>
                </Stack>
                
            </AccordionSummary>
            <AccordionDetails>
                <TelemetryItem itemLabel="Callsign" itemValue={focusedObject.callsign} />
                <TelemetryItem itemLabel="Data Source" itemValue={focusedObject.metadata.source} />
                <TelemetryItem itemLabel="Latest Report" itemValue={(new Date(focusedObject.lastReportTimestamp).toISOString().split('T')[1].split('.')[0]) + ' UTC'} />
                <TelemetryItem itemLabel="Seconds Since Last Report" itemValue={((Date.now() - focusedObject.lastReportTimestamp) / 1000).toFixed(2)} />
                <Stack direction='row' alignItems='center' justifyContent={'space-between'}>
                    <Typography sx={{fontWeight:'bold', fontSize: "14px"}}>Pilot View</Typography>
                    <Typography>
                        {isPilotModeEnabled ? 
                            <ToggleOn onClick={() => disablePilotMode()} sx={{cursor: 'pointer', color: "lime"}}/> : 
                            <ToggleOff onClick={() => enablePilotMode()} sx={{cursor: 'pointer', color: "gray"}}/>
                        }
                    </Typography>
                </Stack>
            </AccordionDetails>
        </PilotPanel>
    )
}