import { useContext, useState } from 'react';
import config from '../config'; 
import axios from 'axios';
import { AuthContext } from '../contexts/auth-context';

export const useApi = () => {
    const { getToken } = useContext(AuthContext);
    
    const parseResult = async (req: any) => {
        try {
            const res = await req();
            return {
                data: res.data.data,
                err: null,
            }
        } catch(err: any) {
            return {
                data: null,
                err: err.message,
            }
        }
    }

    const create = async (entity: string, objData: any) => {
        return parseResult(() => axios.post(`${config.apiUrl}/${entity}`, objData, { headers: { Authorization: `Bearer ${getToken()}`}}));
    }

    const fetchAll = async (entity: string, params: any) => {
        return parseResult(() => axios.get(`${config.apiUrl}/${entity}`, { params, headers: { Authorization: `Bearer ${getToken()}`}}));
    }

    const fetchSingle = async (entity: string, id: any) => {
        return parseResult(() => axios.get(`${config.apiUrl}/${entity}/${id}`, { headers: { Authorization: `Bearer ${getToken()}`}}));
    }

    const updateSingle = async (entity: string, id: any, updateObjData: any) => {
        return parseResult(() => axios.put(`${config.apiUrl}/${entity}/${id}`, updateObjData, { headers: { Authorization: `Bearer ${getToken()}`}}));
    }

    const deleteSingle = async (entity: string, id: any) => {
        return parseResult(() => axios.delete(`${config.apiUrl}/${entity}/${id}`, { headers: { Authorization: `Bearer ${getToken()}`}}));
    }
    const createSMS = async (entity: string, objData: any) => {
        return parseResult(() => axios.post(`${config.smsUrl}`, objData, { headers: { Authorization: `Bearer ${getToken()}`}}));
    }


    return {
        create,
        fetchAll,
        fetchSingle,
        updateSingle,
        deleteSingle,
        createSMS
    }
}