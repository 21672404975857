import { GeometryLayersContext } from "@/contexts/geometry-layers-context";
import { ExpandMore, Language } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Stack, Typography, styled } from "@mui/material";
import { useContext, useState } from "react";
import ReactLoading from 'react-loading';

export const MissionPlanningGeolayers = styled(Accordion)({
    backgroundColor: '#05233b',
    color: 'white',
    border: '1px solid #103d6a',
    width:"300px",
    borderRadius: '5px',
    '&:hover': {
        cursor: 'pointer',
        border: '1px solid #dedede',
    },
});

interface IEntityItemProps {
    itemLabel: string,
    itemValue: any
}


export const EntityItem: React.FC<IEntityItemProps> = ({itemLabel, itemValue}: IEntityItemProps) => {
    return(
        <Stack direction='row' alignItems='center' justifyContent={'space-between'}>
            <Typography sx={{fontWeight:'bold', fontSize: "14px"}}>{itemLabel}</Typography>
            <Typography sx={{fontSize: "14px"}}>{itemValue}</Typography>
        </Stack>
    )
}


export const MissionPlanningGeolayersPanel = () => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { geometryLayers, toggleLayerVisibility, isBusy } = useContext(GeometryLayersContext);

   
    return (
        <MissionPlanningGeolayers onChange={() => setExpanded(!expanded)} expanded={expanded}>
             <AccordionSummary expandIcon={<ExpandMore sx={{color:"white"}}/>} >
                <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px">
                    <Language sx={{width: '20px'}}/> 
                    <Typography sx={{fontSize: '14px' , fontWeight:'bold'}}>Geo Layers</Typography>
                </Stack>
                
            </AccordionSummary>
            <AccordionDetails>
            {isBusy ? <ReactLoading type={'bubbles'} color={'#0f68ac'} height={"100px"} width={"100px"} /> : 
                
                geometryLayers && geometryLayers.length > 0 && geometryLayers.map((layer: any, index) => (
                        <EntityItem key={`geometry-layer-${index}`} itemLabel={layer.title} itemValue={(<Checkbox checked={layer.isVisible} onChange={() => toggleLayerVisibility(layer.id)}/>)} />
                    ))
                }
                
            </AccordionDetails>
        </MissionPlanningGeolayers>
    )
}