import { MapContext } from "@/contexts/map-context"
import { Home } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import { useContext } from "react"

export const MapHomeButton = styled(IconButton)({
    backgroundColor: 'white',
    color: '#bebebe',
    border: '2px solid #cecece',
    
    '&:hover': {
        backgroundColor: '#efefef',
        border: '2px solid #dedede',
    },
});

export const MapHome = () => {
    const { center, gotoLocation, disablePilotMode } = useContext(MapContext);

    return (
        <MapHomeButton onClick={() => { disablePilotMode(); gotoLocation({ center: center, zoom: 8 }) }}>
            <Home />
        </MapHomeButton>
    )
}