import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import axios from 'axios';

import { ConfigContext } from "./config-context";
import { LoginPage } from "@/pages/login-page";
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router-dom";
import ReactLoading from 'react-loading';

interface IAuthContext {
    token: any,
    isBusy: boolean, 
    isLoading: boolean,
    error:any,
    isLoggedIn:boolean,
    authenticate: (username: string, password: string) => any,
    logout: () => void,
    getToken: () => any,
}

export const AuthContext = createContext<IAuthContext>({
    token: null,
    isBusy: false,
    isLoading: true,
    error:null,
    isLoggedIn:false,
    authenticate: (username:string, password:string) => {},
    logout: () => {},
    getToken: () => {},
});


interface IContextProps {
    children?: ReactNode
}

const AuthContextProvider = ({ children }: IContextProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    // Load token from cookie
    const loadToken = () => {
        console.log("Load token...");
        const cookieToken = Cookies.get('token');
        const loadedToken =  cookieToken ? JSON.parse(cookieToken) : null;
        console.log("Loaded token...");
        if (loadedToken && !isTokenExpired(loadedToken)) {
            // console.log('loaded token expired', isTokenExpired(loadedToken));
            setIsLoggedIn(true);
            setToken(loadedToken);
        }
        setIsLoaded(true);
        return loadedToken;
    }

    

    const { config: credentials, config: {services: { authService: { host, port }}}} = useContext(ConfigContext);
    const [isBusy, setIsBusy] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [token, setToken] = useState<any>(null); 
    
    useEffect(() => {
        if(!isLoaded) { 
            loadToken();
        }
    },[isLoaded]);
   
   
    /**
     * Check if JWT token has expired
     * @param {} token 
     * @returns 
     */
    const isTokenExpired = (token:any) => {
        const now = new Date();
        
        // Invalid token is counted as expired
        if (token == null) {
            return true;
        }

        const tokenExp = new Date(token.exp );
        const anHourBefore = new Date(token.exp - 1000 * 60 * 60);
        if(tokenExp < anHourBefore) {
            authRefreshToken(token).then( () => {return false;}).catch(() => {return true});
        }
        
        if (tokenExp < now) {
            removeToken();
            return true;
        }

        return false;
    }


    /**
     * Get single token
     */
    const getToken = () => {
        
        if (token == null) {
            return null;
        }

        if (isTokenExpired(token)) {
            removeToken();
            return null;
        }
        return token.access_token;
    }

    /**
     * Remove existing token
     */
    const removeToken = () => {
        setToken(null);
        Cookies.remove('token');
    }

    /**
     * Save received token
     * @param {} t 
     */
    const storeToken = (t:any) => {
        setToken(t);
        Cookies.set('token', JSON.stringify(t));
    }


    /**
     * Get tokens
     */
    const authenticate = async (username:string, password:string) => {
        setIsBusy(true);
        
        return axios.post(`${host}/token`, {
                    grant_type: "password",
                    username,
                    password,
                }).then((response) => {

                    const { access_token, expires_in, refresh_token } = response.data;
                    console.log("Resp", response)
                    const token = {
                        access_token,
                        exp: (new Date()).getTime() + (expires_in * 1000),
                        refresh_token
                    };
                    storeToken(token);
                    setIsLoggedIn(true);
                    setError(null);
                    setIsBusy(false);
                   
                }).catch(err => { 
                    setToken(null);
                    removeToken();
                    setIsLoggedIn(false);
                    setError(err.message);
                    setIsBusy(false);
                    
                });
           
    }

    const authRefreshToken = async (token:any) => {
        setIsBusy(true);
        
        return new Promise<void>(async (resolve, reject) => {
            await axios.post(`${host}/token`, {
                    grant_type: "refresh_token",
                    refresh_token: token.refresh_token,
                    
                }).then((response) => {

                    const { access_token, expires_in, refresh_token } = response.data;
                    
                    const token = {
                        access_token,
                        exp: (new Date()).getTime() + (expires_in * 1000),
                        refresh_token 
                    };
                    storeToken(token);
                    setIsLoggedIn(true);
                    setError(null);
                    setIsBusy(false);
                   
                }).catch(err => { 
                    setToken(null);
                    removeToken();
                    setIsLoggedIn(false);
                    setError(err.message);
                    setIsBusy(false);
                    
                });
           
        })
    }


    
    const logout = () => {
		
        setIsBusy(true);
		if (isLoggedIn) {
			setIsLoading(false);
            console.log("deleting token");
            removeToken();
            setIsLoggedIn(false);
		}
        setIsBusy(false);

	}

    
	
    return (
        <AuthContext.Provider value={{ 
            token,  
            authenticate,
            getToken,
            isLoggedIn,
            isBusy,
            error,
            logout,
            isLoading
        }}>
            { !isLoaded ? <ReactLoading type={'bubbles'} color={'#0f68ac'} height={"100px"} width={"100px"} /> : isLoggedIn ? children : <LoginPage/> }
        </AuthContext.Provider>
    )
}




export default AuthContextProvider;