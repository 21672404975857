
import { RouteSMSNotificationsContext } from "@/contexts/route-sms-notifications-context";
import { Adjust, Delete, ExpandMore, FmdBad, Home, OpenInNew, RadioButtonChecked, RadioButtonUnchecked, Save, TripOrigin, UploadFile } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Input, MenuItem, Select, Stack, Typography, styled } from "@mui/material";
import { useContext, useState } from "react";
// import {useDistanceRings} from '@/hooks/use-distance-rings';
import ReactLoading from 'react-loading';





export const MissionPlanningRiskPanelActions = () => {

 
    return (
        <Stack 
            direction='column' 
            alignItems={'flex-start'} 
            justifyContent={'space-between'} 
            gap="5px" 
            sx={{width: '100%', marginBottom: '10px', borderTop: "1px solid gray", borderBottom: "1px solid gray", padding: "10px 0", marginTop: "10px"}}>
            <Typography sx={{fontSize:'16px', fontWeight:'bold'}}>Mission Actions</Typography>
            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} gap="5px" sx={{width: '100%', marginBottom: '10px'}}>
                <Button variant='outlined' sx={{fontSize: '12px',color: 'white', border: '1px solid white'}}  startIcon={<Save/>}> Save</Button>
                <Button variant='outlined' sx={{fontSize: '12px',color: 'white', border: '1px solid white'}} startIcon={<UploadFile/>}> Load</Button>
                <Button variant='outlined' sx={{fontSize: '12px',color: 'white', border: '1px solid white'}} startIcon={<Delete/>}> Delete</Button>
            </Stack>
            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} gap="5px" sx={{width: '100%'}}>
                <p>Currently loaded mission:</p>
                <p style={{fontWeight: 'bold'}}>123 test</p>
            </Stack>
        </Stack>
    )
}