
import { RouteSMSNotificationsContext } from "@/contexts/route-sms-notifications-context";
import { Adjust, ExpandMore, FmdBad, Home, RadioButtonChecked, RadioButtonUnchecked, TripOrigin } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Input, MenuItem, Select, Stack, Typography, styled } from "@mui/material";
import { useContext, useState } from "react";
// import {useDistanceRings} from '@/hooks/use-distance-rings';
import ReactLoading from 'react-loading';


export const MissionPlanningRisk = styled(Accordion)({
    backgroundColor: '#05233b',
    color: 'white',
    border: '1px solid #103d6a',
    // position: 'absolute',
    // top: '250px',
    // left: '10px',
    width:"300px",
    borderRadius: '5px',
    '&:hover': {
        // backgroundColor: '#efefef',
        cursor: 'pointer',
        border: '1px solid #dedede',
    },
});



export const MissionPlanningRiskPanel = () => {
   
    const [expanded, setExpanded] = useState<boolean>(false);
 
    return (
        <MissionPlanningRisk onChange={() => setExpanded(!expanded)} expanded={expanded}>
            <AccordionSummary expandIcon={<ExpandMore sx={{color:'white'}}/>} sx={{display:'flex', flexDirection: 'row', alignItems:'center', justifyContent: "space-between"}}>
            <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px" >
                    <FmdBad sx={{width: '20px'}}/> 
                    <Typography sx={{fontSize: '14px', fontWeight:'bold'}}>Risk Assessment</Typography>
                </Stack>
                
            </AccordionSummary>
            <AccordionDetails>
    
                {/* <Typography sx={{color: '#bebebe', fontSize:'14px'}}>Set route notifications</Typography> */}
                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" gap="10px" sx={{marginBottom: '10px'}}>
                    <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} gap="5px" sx={{width: '100%', marginBottom: '10px'}}>
                        <Typography sx={{fontSize:'16px', fontWeight:'bold'}}>Air Risk</Typography>
                        <Button variant='outlined' sx={{fontSize: '12px', color:'white', border: "1px solid white"}}>Est. Risk</Button>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center"  sx={{width: '100%'}}>
                        <span>Air risk assessent: </span>
                        <span style={{fontWeight: 'bold'}}>202</span>
                    </Stack>
                </Stack>
                <hr style={{border:"1px solid gray"}}/>
                <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{marginTop: "10px"}}>
                    <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} gap="5px" sx={{width: '100%', marginBottom: '10px'}}>
                        <Typography sx={{fontSize:'16px', fontWeight:'bold'}}>Ground Risk</Typography>
                        <Button variant='outlined' sx={{fontSize: '12px', color:'white', border: "1px solid white"}}>Est. Risk</Button>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap="10px" sx={{width: '100%'}}>
                        <span>Ground risk assessent: </span>
                        <span style={{fontWeight: 'bold'}}>202</span>
                    </Stack>
                </Stack>
               
                {/* {isBusy ?
                    <ReactLoading type={'balls'} color={"white"} height={'20%'} width={'20%'} />
                    : <Button variant='contained' fullWidth sx={{marginTop: '20px'}} onClick={() => onConfirmRouteNotif()}>Confirm</Button>} */}
            </AccordionDetails>
        </MissionPlanningRisk>
    )
}