import { BaseMapStyle, IGeoPoint, IMapLocation, IMapScene } from "@/types";
import { useState, ReactNode, createContext, useEffect } from "react";
// import PolygonDrawAction from "@arcgis/core/views/draw/PolygonDrawAction.js";
import MapView from "@arcgis/core/views/MapView";
// import Graphic from "@arcgis/core/Graphic";
// import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
// import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
// import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';

interface IMapContext {
    mapRotation: number,
    baseMap: BaseMapStyle,
    bounds: any,
    center: IGeoPoint,
    targetLocation: IMapLocation | null,
    mapScenes: Array<IMapScene>,
    currentMapScene: string,
    enablePolygon: boolean,
    verticesList: any,
    setEnablePolygon: (enablePolygon: boolean) => void,
    isPilotModeEnabled: boolean,
    focusedObject: any,
    distanceRings: number[],
    mapRotated: number,
    setMapRotation: (rotation: number) => void,
    setBaseMap: (baseMap: BaseMapStyle) => void,
    setBounds: (bounds: any) => void,
    setCenter: (center: IGeoPoint) => void,
    gotoLocation: (IMapLocation: IMapLocation) => void,
    setMapScenes: (scenes: Array<IMapScene>) => void,
    setCurrentMapScene: (title: string) => void,
    // enableCreatePolygon: (draw:any, view:MapView) => void,
    setVerticesList: (verticesList:any) => void
    enablePilotMode: () => void,
    disablePilotMode: () => void,
    focusObject: (obj: any) => void,
    setDistanceRings: (rings: number[]) => void,
    setMapRotated: (rotation: number) => void,
};

export const MapContext = createContext<IMapContext>({
    baseMap: 'hybrid',
    mapRotation: 0,
    mapRotated: 0,
    bounds: null,
    center: { latitude: 0, longitude: 0 },
    targetLocation: { center: { latitude: 0, longitude: 0 }, zoom: 20},
    mapScenes: [],
    currentMapScene: 'main',
    enablePolygon: false,
    verticesList: [],
    distanceRings: [0,0,0],
    setEnablePolygon: (_:boolean) => {},
    isPilotModeEnabled: false,
    focusedObject: null,
    setMapRotation: (_: number) => {},
    setMapRotated: (_: number) => {},
    setBaseMap: (_: string) => {},
    setBounds: (_: any) => {},
    setCenter: (_: IGeoPoint) => {},
    gotoLocation: (_: IMapLocation) => {},
    setMapScenes: (_: Array<IMapScene>) => {},
    setCurrentMapScene: (_: string) => {},
    // enableCreatePolygon: (draw:any, view:MapView) => {},
    setVerticesList: (_:any) => {},

    enablePilotMode: () => {},
    disablePilotMode: () => {},
    focusObject: (_: any) => {},
    setDistanceRings: (_: number[]) => {},
});

interface IContextProps {
    children?: ReactNode
}

const MapContextProvider = ({ children }: IContextProps) => {
    const [center, setCenter] = useState<IGeoPoint>({ latitude: 47.0746822, longitude: 21.8550442,
})
    const [mapRotation, setMapRotation] = useState<number>(0);
    const [mapRotated, setMapRotated] = useState<number>(0);
    const [baseMap, setBaseMap] = useState<BaseMapStyle>('hybrid');
    const [bounds, setBounds] = useState<any>(null);
    const [targetLocation, setTargetLocation] = useState<IMapLocation | null>(null)
    const [mapScenes, setMapScenes] = useState<Array<IMapScene>>([{ title: 'main', objects: [] }]);
    const [currentMapScene, setCurrentMapScene] = useState<string>('main');
    const [enablePolygon, setEnablePolygon] = useState<boolean>(false);
    const [verticesList, setVerticesList] = useState<any>([]);
    const [focusedObject, setFocusedObject] = useState<any>(null);
    const [isPilotModeEnabled, setIsPilotModeEnabled] = useState<boolean>(false);
    const [distanceRings, setDistanceRings] = useState<number[]>([20, 80, 150]);

    const focusObject = (obj: any) => {
        if (focusedObject != null) {
            focusedObject.unsubscribe(this);
        }
        setMapRotation(0);
        setFocusedObject(obj);
    }

    const enablePilotMode = () => {
        if (focusedObject) {
            setIsPilotModeEnabled(true);
            setMapRotation(-focusedObject.attitude.heading);
            gotoLocation({ 
              center: { 
                  latitude: focusedObject.attitude.position.latitude, 
                  longitude: focusedObject.attitude.position.longitude
              }, 
              zoom: 18,
          })
          console.log('Rotate map: ', focusedObject.attitude.heading)
          focusedObject.setPilotMode(true);
            focusedObject.subscribe(this, (object: any) => {
                setMapRotation(-object.attitude.heading);
                gotoLocation({ 
                    center: { 
                        latitude: object.attitude.position.latitude, 
                        longitude: object.attitude.position.longitude
                    }, 
                    zoom: null,
                })
            })
          
        }
    }
    
    useEffect(() => {
      if (focusedObject) {
        focusedObject.updateDistanceRings(distanceRings);
      }
    }, [distanceRings]);

    const disablePilotMode = () => {
        if (focusedObject) {
            setIsPilotModeEnabled(false);
            focusedObject.setPilotMode(false);
            focusedObject.unsubscribe(this);
            setMapRotation(0);
        }
    }

    const gotoLocation = (IMapLocation: IMapLocation) => {
        setTargetLocation(IMapLocation); 
    }

    // const enableCreatePolygon = (draw:any, view:MapView) => {
    //     let action = draw.create("polygon");
      
    //     // PolygonDrawAction.vertex-add
    //     // Fires when user clicks, or presses the "F" key.
    //     // Can also be triggered when the "R" key is pressed to redo.
    //     action.on("vertex-add", (evt:any) => {
    //       createPolygonGraphic(evt.vertices, view);
    //     });
      
    //     // PolygonDrawAction.vertex-remove
    //     // Fires when the "Z" key is pressed to undo the last added vertex
    //     action.on("vertex-remove", (evt:any) => {
    //       createPolygonGraphic(evt.vertices, view);
    //     });
      
    //     // Fires when the pointer moves over the view
    //     action.on("cursor-update", (evt:any) => {
    //       createPolygonGraphic(evt.vertices, view);
    //     });
      
    //     // Add a graphic representing the completed polygon
    //     // when user double-clicks on the view or presses the "Enter" key
    //     action.on("draw-complete", (evt:any) => {
    //       createPolygonGraphic(evt.vertices, view);
    //     });
    //   }
      
    //   const createPolygonGraphic = (vertices:string, view:MapView) => {
    //     view.graphics.removeAll();
    //     let polygon = {
    //       type: "polygon", // autocasts as Polygon
    //       rings: vertices,
    //       spatialReference: view.spatialReference
    //     };
      
    //     let graphic = new Graphic({
    //       geometry: polygon,
          
    //       symbol: new SimpleFillSymbol( {
    //         // type: "simple-fill", // autocasts as SimpleFillSymbol
            
    //         color: [ 131, 235, 52, 0.2 ],
    //         style: "solid",
    //         outline: new SimpleLineSymbol( {  // autocasts as SimpleLineSymbol
    //           color: "lime",
    //           width: 1
    //         })
    //       })
    //     })
    //     ;
    //     view.graphics.add(graphic);
    //   }
     
    return (
        <MapContext.Provider value={{ 
            mapRotation, 
            baseMap,
            bounds,
            center,
            targetLocation,
            mapScenes,
            currentMapScene,
            enablePolygon,
            verticesList,
            distanceRings,
            setEnablePolygon,
            isPilotModeEnabled,
            focusedObject,
            mapRotated,
            setMapRotation,
            setBaseMap,
            setBounds,
            setCenter,
            gotoLocation,
            setMapScenes,
            setCurrentMapScene,
            // enableCreatePolygon,
            setVerticesList,
            enablePilotMode,
            disablePilotMode,
            focusObject,
            setDistanceRings,
            setMapRotated,
        }}>
            { children }
        </MapContext.Provider>
    )
}

export default MapContextProvider;