import process from 'process'

const config = {
    env: {
        mqtt_host: process.env.REACT_APP_MQTT_HOST,
        mqtt_port: process.env.REACT_APP_MQTT_PORT,
        mqtt_username: process.env.REACT_APP_MQTT_USERNAME,
        mqtt_password: process.env.REACT_APP_MQTT_PASSWORD
    },
    test: "test",
    services: {
		authService: {
			host: 'http://ec2-3-17-13-212.us-east-2.compute.amazonaws.com',
			port: 10001,
		},
        cognito: {
            userPoolId: 'eu-central-1_HTItMOtq8',
            clientId: '5ke7ti40nomekqsflq1lhfgcof',
        },
       
    },
    environment: 
        window.location.hostname === 'localhost' || window.location.hostname.split('.')[0] === 'cop-staging' ?
        'staging'
        :
        'production'
  
};

export default config;