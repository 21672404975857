import React from "react";

export default {
    apiUrl: 'https://f5libh3ii1.execute-api.ap-southeast-2.amazonaws.com/staging',
    smsUrl: 'https://f5libh3ii1.execute-api.ap-southeast-2.amazonaws.com/staging/sms',
    services: {
        cognito: {
            userPoolId: 'eu-central-1_HTItMOtq8',
            clientId: '24l426np1qcvedd2plmflrsk0l',
        },
        authService: {
			host: 'https://sxqm7qer6e.execute-api.eu-central-1.amazonaws.com',
			// port: 443,
		},

       
    },
    credentials: [
		{
			scope: 'skyy.prod.cop.admin',
			label: 'Production Admin Credential',
		},
		{
			scope: 'skyy.prod.cop.sms',
			label: 'Production SMS Service Credential'
		},
		{
			scope: 'skyy.staging.cop.admin',
			label: 'Staging Admin Credential'
		},
		{
			scope: 'skyy.staging.cop.sms',
			label: 'Staging SMS Service Credential',
			optional: true,
		},
	],
  
}