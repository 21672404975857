import { ReactNode, createContext, useEffect, useRef, useState } from "react";
import * as mqtt from "mqtt";
import { useApi } from "@/hooks/use-api";
import { toast } from "react-toastify";


interface IRouteSMSNotificationsContext {
    routesList: any,
    smsTemplatesList: any,
    SMSNotify:(data:any) => void
    isBusy: boolean,
};

export const RouteSMSNotificationsContext = createContext<IRouteSMSNotificationsContext>({
    routesList: [],
    smsTemplatesList: [],
    SMSNotify: (data: any) => {},
    isBusy: false
});

interface IContextProps {
    children?: ReactNode,
}


const RouteSMSNotificationsContextProvider = ({ children }: IContextProps) => {
    
    const [routesList, setRoutesList] = useState<any[]>([]);
    const { fetchAll , createSMS } = useApi();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [smsTemplatesList, setSMSTemplatesList] = useState<any[]>([]);

    const fetchRoutesList = async () => {
        console.log('Fetching routes list');
        try {
            const response = await fetchAll('routes', {});
            const newRoutesList = response.data.map((route: any) => ({
                id: route.id,
                title: route.title,
                description: route.description,
                departure: route.departure,
                destination: route.destination,
                phone_numbers: route.phone_numbers,
             }));

            setRoutesList(newRoutesList)

        } catch (err) {
            console.log(err);
        }
    }
    const fetchSMSTemplates = async () => {
        console.log('Fetching sms templates list');
        try {
            const response = await fetchAll('sms-templates', {});
            const newSMSTemplatesList = response.data.map((route: any) => ({
                id: route.id,
                title: route.title,
             }));
            console.log("SMS TEMP", response)
            setSMSTemplatesList(newSMSTemplatesList)

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchRoutesList();
        fetchSMSTemplates();
    }, []);

    const SMSNotify = async (data: any) => {
        setIsBusy(true);
        console.log("SMS NOTIFY")
        try {
            const response = await createSMS('sms', data);
            console.log("-----SMS NOTIFY------", response);
            console.log("response", response)
           
            if(!response.err) {
                console.log("resp", response)
                toast.success("Notification sent")
                setIsBusy(false);
            }
        } catch (err) {
            console.log(err);
            toast.error("Failed to send notification");
            setIsBusy(false);
        }

    }
   
    return (
        <RouteSMSNotificationsContext.Provider value={{ routesList, SMSNotify, isBusy, smsTemplatesList }}>
            {children}
        </RouteSMSNotificationsContext.Provider>
    )
}

export default RouteSMSNotificationsContextProvider;