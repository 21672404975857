import React, { useContext, useEffect, useRef } from 'react';

import {Button, Input, Stack, styled } from "@mui/material";
import { AuthContext } from '@/contexts/auth-context';
import logo from '@/assets/logo/logo.png';
import background from '@/assets/backgrounds/drona3.jpg';


const LoginScreenDiv = styled('div')({
    position: 'absolute',
	width: '100vw',
	height: '100vh',
	backgroundColor: '#001E29',
	zIndex: '100000',
	top: 0,
	left: 0,

})


export const LoginPageWrap = styled(Stack)({
    flexDirection:"column",
    alignItems:'center', 
    justifyContent:'center' ,
    width:"100%",
    height: "100%", 


});

export const LoginForm = styled(Stack)({
    flexDirection:"column",
    alignItems:'center', 
    justifyContent:'center' ,
	gap:'20px',
	color: 'white'
   
});

const LoginButton = styled(Button)({
	transition: '0.2s',
	backgroundColor: '#ffffff00',
	border: '1px solid gray',
	color: 'white',
	//letter-spacing: 2px;	
	display: 'inline-block',
	padding:' 10px 20px',
	letterSpacing: '5px',
	borderRadius: '5px',
	boxShadow: '0px 0px 9px 4px #ffffff3c',
	marginTop: '10px',
	textTransform: 'uppercase',
	cursor: 'pointer',

})



export const LoginPage = () => {
	// Hooks
	const { authenticate, isBusy, error } = useContext(AuthContext);
	//requiredNewPassword, changePassword isLoading ,
	const usernameRef = useRef<any>(null);
	const passwordRef = useRef<any>(null);
	const newUserAttr = useRef<any>({});

	const login = () => {
		
		const username = usernameRef.current;
		const password = passwordRef.current;

		if (username == null || password == null) {
			// TODO: Handle invalid login inputs
			return;
		}

		if (username.trim().length === 0 || password.trim().length === 0) {
			// TODO: Handle invalid login inputs
			return;
		}

		authenticate(username, password)
	}
	
	useEffect(() => {
		const keyDownHandler = (event:any) => {
	
		  if (event.key === 'Enter') {
			event.preventDefault();
			login();
		  }
		};
	
		document.addEventListener('keydown', keyDownHandler);
	
		return () => {
		  document.removeEventListener('keydown', keyDownHandler);
		};
	  }, []);

	return (
		<LoginScreenDiv className='login-screen'>
			<LoginPageWrap
				key="login-page-wrap"
			>
				<div className='login-logo-wrap'>
							<img src={logo} alt="logo"/>
						</div>
			
						
						{/* {
							requiredNewPassword ?
							<LoginForm>
							<h2 className="login-form-title uppercase">Additional data required</h2>

							<Input 
								className="password-input" 
								type="password" 
								// label="New password"
								placeholder="Your new password"
								// labelPosition="top"
								// labelColor="white"
								onChange={(e:any) => passwordRef.current = e.target.value}
								onSubmit={() => changePassword(passwordRef.current, newUserAttr.current)}
								sx={{color: 'white'}}
							/>

							{
								userAttr != null && Object.keys(userAttr).filter(key => userAttr[key] == null || userAttr[key].length == 0).map(key => (
									<Input 
										sx={{color: 'white'}}
										className="password-input" 
										type="text" 
										// label={key}
										placeholder={`Enter ${key}`}
										// labelPosition="top"
										// labelColor="white"
										onChange={(e:any) => newUserAttr.current[key] = e.target.value}
										onSubmit={() => changePassword(passwordRef.current, newUserAttr.current)}
									/>
								))
							}

					<div className='error-message'>
						{ error && <p>
							{error}
							<br/>
						</p>
						}
						</div>

							<LoginButton
								onClick={() => changePassword(passwordRef.current, newUserAttr.current)}>
								Confirm new password
							</LoginButton>
							</LoginForm>
							: */}

						<LoginForm>
						<h2 className="login-form-title uppercase">Login</h2>

						<Input 
							className="username-input" 
							type="text" 
							// label="Username" 
							placeholder="Your username"
							// labelPosition="top"
							// labelColor="white"
							onChange={(e:any) => usernameRef.current = e.target.value}
							onSubmit={() => login()}
							sx={{color: 'white'}}
						/>

						<Input 
							className="password-input" 
							type="password" 
							// label="Password"
							placeholder="Password"
							// labelPosition="top"
							// labelColor="white"
							onChange={(e:any) => passwordRef.current = e.target.value}
							onSubmit={() => login()}
							sx={{color: 'white'}}
						/>

						{/* { !isLoading &&  */}
						{isBusy ?
							<p>Loading ...</p>
						:
							<LoginButton onClick={login}  type="submit">
								Login
							</LoginButton>
				
						}
						{/* } */}
						<div className='error-message'>
						{ error && <p>
							Failed to login.
							<br/>
							Check your credentials 
						</p>
						}
						</div>
						</LoginForm>
				{/* } */}
					
			</LoginPageWrap>
		</LoginScreenDiv>
	)
}