import { MapContext } from "@/contexts/map-context"
import { Navigation } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import { useContext } from "react"

const RotationIndicator = styled(IconButton)({
    backgroundColor: 'white',
    color: '#bebebe',
    border: '2px solid #cecece',
    
    '&:hover': {
        backgroundColor: '#efefef',
        border: '2px solid #dedede',
    },
});

export const MapRotationIndicator = () => {
    const { mapRotation, setMapRotation } = useContext(MapContext);

    return (
        <RotationIndicator onClick={() => setMapRotation(0)}>
            <Navigation sx={{  transform: `rotate(${mapRotation}deg)`, ...(mapRotation % 360 !== 0 && { color: 'red' }) }}/>
        </RotationIndicator>
    )
}