import { ReactNode, createContext, useEffect, useRef, useState } from "react";
import { useApi } from "@/hooks/use-api";

interface IEntityTypesContext {
    entityTypes: any[],
    entityTypesMap: {[key: string]: any},
    fetchEntityTypes: () => void,
    toggleEntityTypeVisibility: (_:any) => void,
    isBusy: boolean,
};

export const EntityTypesContext = createContext<IEntityTypesContext>({
    entityTypes: [],
    entityTypesMap: {},
    fetchEntityTypes: () => {},
    toggleEntityTypeVisibility: (_:any) => {},
    isBusy: false
});

interface IContextProps {
    children?: ReactNode,
}

const EntityTypesContextProvider = ({ children }: IContextProps) => {
    const [entityTypes, setEntityTypes] = useState<any[]>([]);
    const [entityTypesMap, setEntityTypesMap] = useState<any>({});
    const { fetchAll } = useApi();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const toggleEntityTypeVisibility = (id: any) => {
        if (entityTypes.length > 0) {
            const entityType = entityTypes.find(el => el.id === id);
            const newStatus = !entityType.isVisible;
            entityType.isVisible = newStatus;

            entityTypesMap[entityType.constant].isVisible = newStatus;

            setEntityTypesMap({ ...entityTypesMap });
            setEntityTypes([...entityTypes]);
        }
    }

    const fetchEntityTypes = async () => {
        console.log('Fetching entity types');
        setIsBusy(true);
        try {
            const response = await fetchAll('entity-types', {});
            console.log(response);
            console.log('-------')
            const newEntityTypes = response.data.map((entityType: any) => ({
                id: entityType.id,
                title: entityType.title,
                isVisible: entityTypes.find(el => el.id === entityType.id) ? entityTypes.find(el => el.id === entityType.id).isVisible : true,
                constant: entityType.constant,
                horizontal_separation: entityType.horizontal_separation,
                vertical_separation: entityType.vertical_separation,
             }));

            setEntityTypes(newEntityTypes)
            console.log(newEntityTypes);

            const newEntityTypesMap: any = {};
            for (const entityType of newEntityTypes) {
                newEntityTypesMap[entityType.constant] = entityType;
            }

            console.log(newEntityTypesMap);
            setEntityTypesMap(newEntityTypesMap);
        } catch (err) {
            console.log(err);
        }
        setIsBusy(false);
    }

    useEffect(() => {
        fetchEntityTypes();
    }, []);

    return (
        <EntityTypesContext.Provider value={{ fetchEntityTypes, entityTypes, toggleEntityTypeVisibility, entityTypesMap, isBusy }}>
            {children}
        </EntityTypesContext.Provider>
    )
}

export default EntityTypesContextProvider;