import { useConfig } from "@/hooks/use-config";
import { Note } from "@/components/note";
import { useContext, useEffect, useState } from "react";
import { MapContext } from "@/contexts/map-context";
import { TelemetryPanel } from "@/components/panels/telemetry-panel/telemetry-panel";
import { DistanceRingsPanel } from "@/components/panels/distance-rings-panel/distance-rings-panel";
import { FilterEntitiesPanel } from "@/components/panels/filter-entity-types-panel/filter-entity-types-panel";
import styled from "@emotion/styled";
import { Button, Stack, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, Logout } from "@mui/icons-material";
import { InterfaceContext } from "@/contexts/interface-context";
import { motion } from "framer-motion";
import { FilterLayersPanel } from "@/components/panels/filter-layers-panel/filter-layers-panel";

import { RouteSMSNotificationsPanel } from "@/components/panels/route-sms-notifications-panel/route-sms-notifications-panel";
import { AuthContext } from "@/contexts/auth-context";
import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { SidePanel } from "@/components/panels/side-panel/side-panel";




export const HomePage = () => {
    const config = useConfig();
    const { isPilotModeEnabled, focusedObject} = useContext(MapContext);
    const {sideMenuOpen, setSideMenuOpen} = useContext(InterfaceContext);

    const { token, isLoggedIn} = useContext(AuthContext);
    
    const location = useLocation();
    const [isSMSVisible, setIsSMSVisible] = useState(false);
    
    useEffect(() => {
        if (token != null) {
                const decoded:any = jwtDecode(token.access_token);
                
                if( config.environment == 'staging') {
                    if(decoded.scope.split(" ").filter((p:any) => p == 'skyy.staging.cop.sms').length == 1) {
                        setIsSMSVisible(true);
                    }
                }

                if( config.environment == 'production') {
                    if(decoded.scope.split(" ").filter((p:any) => p == 'skyy.prod.cop.sms').length == 1) {
                        setIsSMSVisible(true);
                    }
                }
             
        }
    }, [isLoggedIn]);

    return (
        <SidePanel>   
            {focusedObject && <TelemetryPanel/>}
            {focusedObject && isPilotModeEnabled && <DistanceRingsPanel/>}
            <FilterEntitiesPanel/>
            <FilterLayersPanel/>
            {isSMSVisible && <RouteSMSNotificationsPanel/>}  
        </SidePanel>
    )
}