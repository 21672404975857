import { useConfig } from "@/hooks/use-config";
import { Note } from "@/components/note";
import { ReactNode, useContext, useEffect, useState } from "react";
import { MapContext } from "@/contexts/map-context";
import { TelemetryPanel } from "@/components/panels/telemetry-panel/telemetry-panel";
import { DistanceRingsPanel } from "@/components/panels/distance-rings-panel/distance-rings-panel";
import { FilterEntitiesPanel } from "@/components/panels/filter-entity-types-panel/filter-entity-types-panel";
import styled from "@emotion/styled";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, Home, Logout, Timeline } from "@mui/icons-material";
import { InterfaceContext } from "@/contexts/interface-context";
import { motion } from "framer-motion";
import { FilterLayersPanel } from "@/components/panels/filter-layers-panel/filter-layers-panel";
import logo from '@/assets/logo/logo.png';
import { RouteSMSNotificationsPanel } from "@/components/panels/route-sms-notifications-panel/route-sms-notifications-panel";
import { AuthContext } from "@/contexts/auth-context";
import { useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

export const SidePanelDiv = styled('div')({
    backgroundColor: '#010b26aa!important',
    // backgroundColor: "pink",
    color: 'white',
    border: '1px solid #103d6a',
    position: 'absolute',
    top: '0px',
    left: '0px',
    height:'95vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: "flex-start",
    justifyContent: 'space-between',
    gap: "10px",
    overflow: 'auto', 
    padding: '20px 10px 450px 10px',
    zIndex: 10000,
    // paddingBottom: 4000,
    
    // borderRadius: '5px',
    // '&:hover': {
    //     // backgroundColor: '#efefef',
    //     cursor: 'pointer',
    //     border: '1px solid #dedede',
    // },
});

export const PanelKnob = styled(Stack)({
    backgroundColor:'#010b26aa!important',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: 'center',
    borderRadius: "0 10px 10px 0",
    padding: '10px',
    width: "20px",
    height: "20px",
    position: 'absolute',
    top: '10px',
    left:'0',
    zIndex: 10000,
}) ;

interface IContextProps {
    children?: ReactNode
}


export const SidePanel = ({children}:IContextProps) => {

    const {sideMenuOpen, setSideMenuOpen} = useContext(InterfaceContext);
    const navigate = useNavigate();
    const {logout, token, isLoggedIn} = useContext(AuthContext);
    const handleLogout = () => {
        if(window.confirm(
			`Do you want to log out?`
		)) {
            logout();
        }
    }
    const location = useLocation();
    const [activeLocation, setActiveLocation] = useState('1');

    useEffect(() => {
        if(location.pathname === '/') {
            setActiveLocation('1');
        }
        if(location.pathname === '/mission-planning') {
            setActiveLocation('2');
        }
    }, [location]);

    return (
    
        <div>
            {!sideMenuOpen ? 
            <PanelKnob>
                <ArrowForwardIos sx={{cursor: 'pointer'}} onClick={() => setSideMenuOpen(true)}/>
            </PanelKnob> : 
            <SidePanelDiv>
                <Stack 
                    sx={{  display: 'flex',
                    flexDirection: 'column',
                    alignItems: "flex-start",
                    justifyContent: 'flex-start',
                    gap: "10px",
                    overflow: 'auto'}}>

                    <Stack direction="row" alignItems={'center'} justifyContent={"space-between"} sx={{width: '100%'}}>
                        <ArrowBackIos sx={{cursor: "pointer"}} onClick={() => setSideMenuOpen(false)}/>
                        <Stack width={"140px"}>
                            <img src={logo} alt="logo"/>
                        </Stack>
                    </Stack>
                    
                   {children}
                </Stack>
                <Stack  justifyContent={'flex-end'} gap="20px">
                    <Stack direction='row' alignItems='center' justifyContent={"flex-start"} gap="20px">
                        
                        <Tooltip title="Home" PopperProps={{style:{zIndex:10000}}}>
                            <Button 
                                sx={{border: '1px solid white', color: 'white', backgroundColor: activeLocation == '1' ? '#121212': 'transparent',  ":hover": {backgroundColor: '#3498db', cursor: 'pointer'}}} 
                                onClick={() => {setActiveLocation('0');navigate('/');}}> 
                                <Home/>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Mission Planning" PopperProps={{style:{zIndex:10000}}}>
                            <Button 
                                sx={{border: '1px solid white', color: 'white', backgroundColor: activeLocation == '2' ? '#121212': 'transparent', ":hover": {backgroundColor: '#3498db', cursor: 'pointer'}}} 
                                onClick={() => {setActiveLocation('1'); navigate('/mission-planning')}}> 
                                    <Timeline/>
                            </Button>
                        </Tooltip>
                    </Stack>
                    
                    <Stack 
                    direction={'row'} 
                    alignItems='center' 
                    justifyContent={'flex-start'} 
                    gap="20px" 
                    onClick={() => handleLogout()} sx={{ cursor:'pointer'}}>
                        <Logout/>
                        <Typography sx={{fontWeight: 'bold'}}>Log out</Typography>
                    </Stack>
                </Stack>
                
            </SidePanelDiv>
            }
        </div>
        
    )
}