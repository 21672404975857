import React, { ReactNode, createContext } from "react";
import config from "@/config";



interface IConfigContext {
    config:any
   
}

export const ConfigContext = createContext<IConfigContext>({
   config:config,
});


interface IContextProps {
    children?: ReactNode
}


const ConfigContextProvider = ({ children }: IContextProps) => {

	return (
		<ConfigContext.Provider value={{config}}>
			{ children }
		</ConfigContext.Provider>
	)
}

export default ConfigContextProvider;