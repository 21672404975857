import { ReactNode, createContext, useEffect, useRef, useState, useContext } from "react";
import * as mqtt from "mqtt";
import { useConfig } from "@/hooks/use-config";
import { GeometryLayersContext } from "./geometry-layers-context";
import { getPreciseDistance } from 'geolib';
import { EntityTypesContext } from "./entity-types-context";
import { useLocation } from "react-router-dom";

// Default age of a position report to consider it stale in milliseconds
// This should be updated to be configurable for each report source type
const DEFAULT_STALE_AGE_MS = 120000


interface IFlightsContext {
    flights: any[],
    // setFlights: (flights: any[]) => void,
};

export const FlightsContext = createContext<IFlightsContext>({
    flights: [],
    // setFlights: (_: any) => {},
});

interface IContextProps {
    children?: ReactNode,
}

const mqttHost = 'wss://0e0ffc9adac449f5912ce7190c499550.s2.eu.hivemq.cloud';
const mqttPort = 8884;
const mqttUsername = 'cop-display';
const mqttPassword = 'C4_4jTgKb2P6xU@';

let fetchedEntityTypesMap = {};

const FlightsContextProvider = ({ children }: IContextProps) => {
    const location = useLocation();
    // console.log(location);
    const aircraft = useRef<any>({});
    const [flights, setFlights] = useState<any[]>([]);
    const { fetchGeometryLayers } = useContext(GeometryLayersContext);
    const { fetchEntityTypes, entityTypesMap } = useContext(EntityTypesContext);

    fetchedEntityTypesMap = entityTypesMap;

    const refreshTimer = useRef<any>(null);
    const client = useRef<any>();
    // const { env: {
    //     mqtt_host: mqttHost,
    //     mqtt_port: mqttPort,
    //     mqtt_username: mqttUsername,
    //     mqtt_password: mqttPassword,
    // }} = useConfig();

    const {environment} = useConfig();

    const hasProximityWarning = (currPoint: any, pointsArr: any[]) => {
        if (currPoint.hLimit > 0 && currPoint.vLimit > 0) {
            // console.log('Points', pointsArr);
            for (const otherPoint of pointsArr) {
                // console.log('Vertical diff', Math.abs(currPoint.altitude - otherPoint.altitude))
                // console.log('Horizontal diff', getPreciseDistance(currPoint.point, otherPoint.point), currPoint.hLimit, otherPoint.hLimit)

                if (currPoint !== otherPoint && otherPoint.hLimit > 0 && otherPoint.vLimit > 0) {
                    if (
                        Math.abs(currPoint.altitude - otherPoint.altitude) < currPoint.vLimit &&
                        getPreciseDistance(currPoint.point, otherPoint.point, 1) < currPoint.hLimit
                    ) {
                        // console.log(getPreciseDistance(currPoint.point, otherPoint.point, 1), currPoint.hLimit);
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const processNotification = async (message: any) => {
        console.log('======MESSAGE======');
        // console.log(JSON.parse(message));
        // console.log(JSON.parse(message).type);
        // console.log(JSON.parse(message).type == 'geometry-layers-updated')
        try {
            if (JSON.parse(message).type == 'geometry-layers-updated') {
                    console.log('Should fetch');
                    fetchGeometryLayers();
            }

            if (JSON.parse(message).type == 'entity-types-updated') {
                console.log('Should fetch entity types');
                fetchEntityTypes();
            }
        } catch (err) {
            console.log(err);
        }
    }

    const processMessage = (message: any, entityTypesMap: any) => {
        if (refreshTimer.current == null) {
            refreshTimer.current = setInterval(() => { 
                console.log('asdsdsds', aircraft.current);
                if (aircraft.current != null) 
                { 
                    setFlights([...Object.values(aircraft.current)]); 
                }
            }, 1000)
        }
        try {
            const {
                timestamp,
                callsign,
                report_source_type,
                entity_type,
                simulated,
                state: {
                    latitude,
                    longitude,
                    altitude_geoid,
                    altitude_pressure,
                    speed,
                    heading,
                    location_data_source,
                }
            } = JSON.parse(message);
            // console.log(JSON.parse(message));

            // Messages that are too old (typically retained messages) will be ignored
            const cutoff = new Date((Date.now() - DEFAULT_STALE_AGE_MS))
            // console.log(new Date(timestamp), cutoff, new Date(Date.now()))

            if (new Date(timestamp) < cutoff) {
                // console.log("Stale report, ignoring...")
                return
            }

            // Ignore SIM flights in production
            if (environment === 'production' && simulated) {
                console.log('Ignore simulated flights')
                return;
            }

            // console.log('FLIGHTS', ...Object.values(aircraft.current))

            if (aircraft.current[callsign] == null) {
                // console.log('FlightsContext: Creating')
                aircraft.current[callsign] = {
                    timestamp,
                    callsign,
                    report_source_type,
                    entity_type,
                    obj: null,
                    state: {
                        latitude,
                        longitude,
                        altitude_geoid,
                        speed,
                        heading,
                        location_data_source,
                    }
                }
            } else {
                // console.log('FlightsContext: Updating')
                // console.log(aircraft.current[callsign])
                aircraft.current[callsign] = {
                    ...aircraft.current[callsign],
                    timestamp,
                    callsign,
                    report_source_type,
                    entity_type,
                    state: {
                        latitude,
                        longitude,
                        altitude_geoid,
                        speed,
                        heading,
                        location_data_source,
                    }
                }
            }

            // console.log(entityTypesMap);
            const allFlights = Object.values(aircraft.current).map((flight: any) => ({
                callsign: flight.callsign,
                point: { 
                    latitude: flight.state.latitude,
                    longitude: flight.state.longitude,
                },
                altitude: flight.state.altitude_geoid,
                hLimit: (entityTypesMap[flight.entity_type] || { horizontal_separation: 0 }).horizontal_separation,
                vLimit: (entityTypesMap[flight.entity_type] || { vertical_separation: 0 }).vertical_separation,
            }));

            // console.log([...Object.values(aircraft.current)])
            // console.log('ALL FLIGHTS', allFlights);


            // for (const currentFlight of allFlights) {
            //     aircraft.current[currentFlight.callsign].proximityWarning = null;

            //     if (hasProximityWarning(currentFlight, allFlights)) {
            //         aircraft.current[currentFlight.callsign].proximityWarning = currentFlight.hLimit;
            //         // console.log('HAS PROXIMITY WARNING')
            //     }
            // }


            
            // console.log([...Object.values(aircraft.current)]);
        } catch (err) { }
    }

    useEffect(() => {
        if (client.current == null) {
            client.current = mqtt.connect(`${mqttHost}:${mqttPort}/mqtt`, {
                username: mqttUsername,
                password: mqttPassword,
                rejectUnauthorized: true,
                // keyPath: __dirname + '/ca.key',
                // certPath: __dirname + '/ca.crt',
                // ca: __dirname +  '/rootCA.key',
            });

            client.current.on('connect', () => {
                console.log('Connected to MQTT')

                client.current.subscribe(`cop/GLOBAL/telemetry/#`, (err: any) => {
                    if (!err) {
                        console.log('Subscribed to', `cop/GLOBAL/telemetry/#`)

                    } else {
                        console.log(err);
                    }
                });

                client.current.subscribe(`cop/GLOBAL/notifications`, (err: any) => {
                    if (!err) {
                        console.log('Subscribed to', `cop/GLOBAL/notifications`)

                    } else {
                        console.log(err);
                    }
                });
            })

            client.current.on('message', (topic: string, message: any, packet: any) => {
                if (topic === 'cop/GLOBAL/notifications') {
                    processNotification(message)
                } else {
                    processMessage(message, fetchedEntityTypesMap);
                }
            });
        }
    }, []);

    return (
        <FlightsContext.Provider value={{ flights }}>
            {children}
        </FlightsContext.Provider>
    )
}

export default FlightsContextProvider;