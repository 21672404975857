import { IMapObject, IMapMarker, IAttitude } from "@/types";
import Graphic from "@arcgis/core/Graphic.js";
import Point from '@arcgis/core/geometry/Point';
import TextSymbol from "@arcgis/core/symbols/TextSymbol.js";
import Polyline from "@arcgis/core/geometry/Polyline.js";
import Circle from "@arcgis/core/geometry/Circle.js";

const greenMarker = require('@/assets/images/map-icons/green-bottom.svg').default;
const yellowMarker = require('@/assets/images/map-icons/yellow-bottom.svg').default;
const redMarker = require('@/assets/images/map-icons/red-bottom.svg').default;

const greenMarkerHdg = require('@/assets/images/map-icons/green-bottom-hdg.svg').default;
const yellowMarkerHdg = require('@/assets/images/map-icons/yellow-bottom-hdg.svg').default;
const redMarkerHdg = require('@/assets/images/map-icons/red-bottom-hdg.svg').default;

const heliIcon = require('@/assets/images/map-icons/heli.svg').default;
const gliderIcon = require('@/assets/images/map-icons/glider.svg').default;
const quadIcon = require('@/assets/images/map-icons/quad.svg').default;
const gcsIcon = require('@/assets/images/map-icons/gcs.svg').default;
const groundvIcon = require('@/assets/images/map-icons/groundv.svg').default;

// import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

export interface IVehicle {
    vehicleType: string
}

const typeMap: {[key: string]: any} = {
    'gcs': {
        bottomMarkers: {
            green: greenMarker,
            yellow: yellowMarker,
            red: redMarker,
        },
        icon: gcsIcon,
        heading: false,
    },
    'heli': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: heliIcon,
        heading: true,
    },
    'glider': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: gliderIcon,
        heading: true,
    },
    'quad': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'groundv': {
        bottomMarkers: {
            green: greenMarker,
            yellow: yellowMarker,
            red: redMarker,
        },
        icon: groundvIcon,
        heading: false,
    },
    'tow': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'drop': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'hgld': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'pwr': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'jet': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'ufo': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'balloon': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'blimp': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'uav': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
    'static': {
        bottomMarkers: {
            green: greenMarkerHdg,
            yellow: yellowMarkerHdg,
            red: redMarkerHdg,
        },
        icon: quadIcon,
        heading: true,
    },
};

export class Vehicle implements IVehicle, IMapObject, IMapMarker {
    public vehicleType: string;
    public graphicsRef: any = null;
    public id: string;
    public callsign: string;
    public attitude: IAttitude;
    public attitudeHistory: IAttitude[] = [];
    public onClick: (obj: any) => void;
    public metadata: any;
    private lastLayers: any = null;
    private isInPilotMode: boolean = false;
    private subscriptions: any = [];
    private distanceRings: number[] = [];
    private mapView: any = null;
    private graphicsLayer: any = null;

    private simpleMarkerSymbol: any = null;
    private vehicleBottomMarker: any = null;
    private vehicleMarker:any = null;

    private point: any = null;
    private pointGraphic: any = null;
    private point2: any = null;
    private point3: any = null;
    private pointGraphic2: any = null;
    private pointGraphic3: any = null;
    
    private label: any = null;
    private trail: any = null;
    private simpleLineSymbol: any = null;
    private polylineGraphic: any = null;
    private dashedLineSymbol: any = null;
    private ring1Geometry: any = null;
    private ring2Geometry: any = null;
    private ring3Geometry: any = null;
    private ring1: any = null;
    private ring2: any = null;
    private ring3: any = null;
    private warningGeometry: any = null;
    private warningRing: any = null;
    private warningLineSymbol: any = null;
    private textGraphic: any = null;
    private lastReportTimestamp: number = 0;
    private heartbeatInterval: any;
    private isRemoved: boolean = false;
    private wasRemoved: boolean = false;
    private hasWarning: boolean = false;

    constructor(
        _id: string,
        _callsign: string,
        _vehicleType: string, 
        _attitude: IAttitude,
        _lastReportTimestamp: number, 
        _onClick: (obj: any) => void, 
        _metadata: any,
        _distanceRings: number[],
        _mapView: any, 
        _graphicsLayer: any,
    ) {
        this.mapView = _mapView; 
        this.graphicsLayer = _graphicsLayer;

        this.id = _id;
        this.callsign = _callsign;
        this.vehicleType = _vehicleType || 'quad';
        this.attitude = _attitude;
        this.onClick = _onClick;
        this.metadata = _metadata;
        this.attitudeHistory = [_attitude];
        this.distanceRings = _distanceRings;
        this.lastReportTimestamp = new Date(_lastReportTimestamp).getTime();
        this.heartbeatInterval = setInterval(() => this.heartbeat(), 1000); 
        this.initGraphics();
    }

    checkMarkerStatus() {
        if (this.vehicleBottomMarker) {
            const now = Date.now();

            if (now - this.lastReportTimestamp > 90000) {
                this.isRemoved = true;
                return;
            }

            if (now - this.lastReportTimestamp <= 3000) {
                this.vehicleBottomMarker.url = typeMap[this.vehicleType.toLowerCase()].bottomMarkers.green;
            } else if (now - this.lastReportTimestamp <= 5000) {
                this.vehicleBottomMarker.url = typeMap[this.vehicleType.toLowerCase()].bottomMarkers.yellow;
            } else {
                this.vehicleBottomMarker.url = typeMap[this.vehicleType.toLowerCase()].bottomMarkers.red;
            }
        }
    }

    heartbeat() {
        this.render();
    }

    initGraphics() {
        // Init base point
        this.point = new Point({ 
            latitude: this.attitude.position.latitude, 
            longitude: this.attitude.position.longitude, 
            spatialReference: { wkid: 3857 } 
        }); 

        this.checkMarkerStatus()

        this.vehicleBottomMarker = { 
            type: "picture-marker", 
            width: 28, 
            height: typeMap[this.vehicleType.toLowerCase()].heading ? 33 : 28, 
            // angle: this.isInPilotMode ? 0 : this.attitude.heading + mapView.rotation, 
            angle: this.attitude.heading,
            ...(typeMap[this.vehicleType.toLowerCase()].heading && {
                xoffset: `${Math.cos((((450 - this.attitude.heading - this.mapView.rotation) % 360) * Math.PI / 180)) * 3}px`,
                yoffset: `${Math.sin((((450 - this.attitude.heading - this.mapView.rotation) % 360) * Math.PI / 180)) * 3}px`,
            }),
            url: greenMarker,
        }; // orange outline: { color: [255, 255, 255], // white width: 1 } };

        this.vehicleMarker = { 
            type: "picture-marker", 
            width: 25, 
            height: 25, 
            // angle: this.isInPilotMode ? 0 : this.attitude.heading + mapView.rotation, 
            angle: this.attitude.heading,
            url: typeMap[this.vehicleType.toLowerCase()].icon,
        }; // orange outline: { color: [255, 255, 255], // white width: 1 } };

        // Init marker point graphics
        // this.pointGraphic = new Graphic({ 
        //     geometry: this.point, 
        //     symbol: this.simpleMarkerSymbol, attributes: { metadata: this.metadata, onClick: () => this.onClick(this)} 
        // }); 
        this.pointGraphic2 = new Graphic({ 
            geometry: this.point,
            symbol: this.vehicleBottomMarker, attributes: { metadata: this.metadata, onClick: () => this.onClick(this)} 
        }); 
        this.pointGraphic3 = new Graphic({ 
            geometry: this.point, 
            symbol: this.vehicleMarker, attributes: { metadata: this.metadata, onClick: () => this.onClick(this)} 
        }); 

        // Init marker text symbol
        this.label = new TextSymbol({
            color: "white",
            haloColor: "black",
            haloSize: "1px",
            text: `${this.callsign}\nSPD: ${this.attitude.speed}\nALT: ${this.attitude.position.altitude}`,
            xoffset: 25,
            yoffset: -25,
            horizontalAlignment: 'left',
            font: {  // autocasts as new Font()
              size: 8,
              family: "Arial",
              weight: "bold"
            }
        })

        // Init marker text graphics
        this.textGraphic = new Graphic({ 
            geometry: this.point, 
            symbol: this.label
        }); 

        // Init trail simple line symbol
        this.simpleLineSymbol = {
            type: "simple-line",
            color: 'blue', // Orange
            width: 2
        };

        // Init distance ring simple line
        this.dashedLineSymbol = {
            type: "simple-line",
            color: 'lime',
            style: 'dash',
            width: 2
        };

        // Init distance ring geometry
        // Rings
        this.ring1Geometry = new Circle({
            hasZ: false,
            hasM: false,
            center: this.point,
            radius: this.distanceRings[0],
            radiusUnit: 'meters',
            numberOfPoints: 100,
            spatialReference: { wkid: 3857 }
          });

          this.ring2Geometry = new Circle({
            hasZ: false,
            hasM: false,
            center: this.point,
            radius: this.distanceRings[1],
            radiusUnit: 'meters',
            numberOfPoints: 100,
            spatialReference: { wkid: 3857 }
          });

          this.ring3Geometry = new Circle({
            hasZ: false,
            hasM: false,
            center: this.point,
            radius: this.distanceRings[2],
            radiusUnit: 'meters',
            numberOfPoints: 100,
            spatialReference: { wkid: 3857 }
          });

        // Init distance rings graphics
        this.ring1 = new Graphic({
            geometry: this.ring1Geometry,
            symbol: this.dashedLineSymbol
        });

        this.ring2 = new Graphic({
            geometry: this.ring2Geometry,
            symbol: this.dashedLineSymbol
        });

        this.ring3 = new Graphic({
            geometry: this.ring3Geometry,
            symbol: this.dashedLineSymbol
        });

        this.trail = new Polyline({
            hasZ: false,
            hasM: false,
            paths: [[]],
        });

        this.polylineGraphic = new Graphic({
            geometry: this.trail,
            symbol: this.simpleLineSymbol
        });

        // Init warning indicator
        this.warningGeometry = new Circle({
            hasZ: false,
            hasM: false,
            center: this.point,
            radius: 100,
            radiusUnit: 'meters',
            numberOfPoints: 100,
            spatialReference: { wkid: 3857 }
        });

        this.warningLineSymbol = {
            type: "simple-line",
            color: 'red',
            style: 'dash',
            width: 1.5
        };

        // Init distance rings graphics
        this.warningRing = new Graphic({
            geometry: this.warningGeometry,
            symbol: this.warningLineSymbol
        });

        // Add to graphics layer
        this.graphicsLayer.add(this.polylineGraphic);
        // this.graphicsLayer.add(this.pointGraphic);
        this.graphicsLayer.add(this.pointGraphic2);
        this.graphicsLayer.add(this.pointGraphic3);
        this.graphicsLayer.add(this.textGraphic);
    }

    subscribe(listener: any, callback: any) {
        this.subscriptions.push({ listener, callback })
    }

    updateDistanceRings(distanceRings: number[]) {
        this.distanceRings = distanceRings;
        this.ring1Geometry = new Circle({
            hasZ: false,
            hasM: false,
            center: this.point,
            radius: this.distanceRings[0],
            radiusUnit: 'meters',
            numberOfPoints: 100,
            spatialReference: { wkid: 3857 }
          });

        this.ring2Geometry = new Circle({
            hasZ: false,
            hasM: false,
            center: this.point,
            radius: this.distanceRings[1],
            radiusUnit: 'meters',
            numberOfPoints: 100,
            spatialReference: { wkid: 3857 }
        });

        this.ring3Geometry = new Circle({
            hasZ: false,
            hasM: false,
            center: this.point,
            radius: this.distanceRings[2],
            radiusUnit: 'meters',
            numberOfPoints: 100,
            spatialReference: { wkid: 3857 }
        });
        
        this.ring2Geometry.radius = distanceRings[1];
        this.ring3Geometry.radius = distanceRings[2];

        this.ring1.geometry = this.ring1Geometry;
        this.ring2.geometry = this.ring2Geometry;
        this.ring3.geometry = this.ring3Geometry;
        // this.render();
    }

    unsubscribe(listener: any) {
        this.subscriptions = this.subscriptions.filter((el: any) => el.listener !== listener);
    }

    update(timestamp: string, _attitude: IAttitude, _metadata?: any) {
        // Ignore stale reports
        if (Date.now() - Math.abs(new Date(timestamp).getTime()) > 90000) { return }

        this.lastReportTimestamp = new Date(timestamp).getTime();
        this.attitude = _attitude;
        this.attitude.speed = _attitude.speed;
        this.attitudeHistory.push(_attitude);
        
        // RE-ENABLE THIS !!!
        if (this.attitudeHistory.length > 240) {
            this.attitudeHistory.shift();
        }
        
        if (_metadata) {
            this.metadata = _metadata;
        }

        for (const sub of this.subscriptions) {
            sub.callback(this);
        }
        
        this.isRemoved = false;
        this.render();
        // this.render(this.lastLayers[0], this.lastLayers[1]);
    }

    setPilotMode(isActive: boolean) {
        this.isInPilotMode = isActive;
        
        if (isActive) {
            this.graphicsLayer.add(this.ring1);
            this.graphicsLayer.add(this.ring2);
            this.graphicsLayer.add(this.ring3);
        } else {
            this.graphicsLayer.remove(this.ring1);
            this.graphicsLayer.remove(this.ring2);
            this.graphicsLayer.remove(this.ring3);
        }

        this.render();
    }

    render() {
        if (this.isRemoved || !this.metadata.isVisible) {
            if (!this.wasRemoved) {
                this.graphicsLayer.remove(this.polylineGraphic)
                this.graphicsLayer.remove(this.pointGraphic2);
                this.graphicsLayer.remove(this.pointGraphic3);
                this.graphicsLayer.remove(this.pointGraphic);
                this.graphicsLayer.remove(this.textGraphic);
                this.wasRemoved = true;
            }
        } else {
            if (this.wasRemoved) {
                this.graphicsLayer.add(this.polylineGraphic)
                this.graphicsLayer.add(this.pointGraphic2);
                this.graphicsLayer.add(this.pointGraphic3);
                this.graphicsLayer.add(this.pointGraphic);
                this.graphicsLayer.add(this.textGraphic);
                this.wasRemoved = false;
            }
        }

        this.checkMarkerStatus();
        this.trail = new Polyline({
            hasZ: false,
            hasM: false,
            paths: this.isRemoved ? [[[]]] : [this.attitudeHistory.map(ah => [ah.position.longitude, ah.position.latitude])],
        });

        if (this.isRemoved) {
            this.attitudeHistory = [];
        }

        this.polylineGraphic.geometry = this.trail;

        this.polylineGraphic.symbol = {
            ...this.simpleLineSymbol
        }
        
        // Update point coordinates
        this.point.latitude = this.attitude.position.latitude; 
        this.point.longitude = this.attitude.position.longitude;

        // console.log('RADIUS', this.metadata.proximityWarning);

        // Update warning indicator
        if (this.metadata.proximityWarning > 0 && !this.isRemoved && this.metadata.isVisible) {
                this.warningGeometry = new Circle({
                    hasZ: false,
                    hasM: false,
                    center: this.point,
                    radius: this.metadata.proximityWarning ? this.metadata.proximityWarning * 1.4 : 0,
                    radiusUnit: 'meters',
                    numberOfPoints: 100,
                    spatialReference: { wkid: 8894 }
                });
        
                this.warningRing.geometry = this.warningGeometry;
            if (!this.hasWarning) {
                this.graphicsLayer.add(this.warningRing);
                this.hasWarning = true;
            }
        } else {
            if (this.hasWarning) {
                this.graphicsLayer.remove(this.warningRing);
                this.hasWarning = false;
            }
        }

        // Update vehicle marker symbol rotation
        this.vehicleMarker.angle = (this.mapView.rotation) + (this.attitude.heading || 0);
        this.vehicleBottomMarker.angle = (this.mapView.rotation) + (this.attitude.heading || 0);

        // if (this.isInPilotMode) {
        //     this.vehicleBottomMarker.xoffset = '0px';
        //     this.vehicleBottomMarker.yoffset = '3px';
        // } else {
        if (typeMap[this.vehicleType.toLowerCase()].heading) {
            this.vehicleBottomMarker.xoffset = `${Math.cos((((450 - (this.attitude.heading || 0) - this.mapView.rotation) % 360) * Math.PI / 180)) * 3}px`;
            this.vehicleBottomMarker.yoffset = `${Math.sin((((450 - (this.attitude.heading || 0) - this.mapView.rotation) % 360) * Math.PI / 180)) * 3}px`;
        }
        // }

        // console.log(this.isInPilotMode);
        this.pointGraphic2.symbol = {
            ...this.vehicleBottomMarker,
        }

        this.pointGraphic3.symbol = {
            ...this.vehicleMarker,
        }
        
        this.updateDistanceRings(this.distanceRings);

        this.textGraphic.geometry = new Point({ 
            latitude: this.attitude.position.latitude, 
            longitude: this.attitude.position.longitude, 
            spatialReference: { wkid: 3857 } 
        }); 

        // Update marker label
        this.label.text = `${this.callsign}\nSPD: ${(this.attitude.speed  * 1.94).toFixed(0)}\nALT: ${(Math.round(this.attitude.position.altitude * 3.2808399 / 10) * 10).toFixed(0)}`;
    }
}