import { MapContext } from "@/contexts/map-context";
import { BaseMapStyle } from "@/types";
import { Paper, Stack, styled } from "@mui/material"
import { useContext, useState } from "react";

const MapStyleSelectorWrapper = styled(Stack)({
    position: 'absolute',
    bottom: 30,
    right: 10,
    borderRadius: 10,
    zIndex: 2,
});

const Thumb = styled(Paper)<{hoverShadow?: number}>(({ theme, hoverShadow=10 }) => ({
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'none',
    backgroundColor: 'none',
    opacity: 0.9,
    padding: 0,
    margin: 0,
    display: "flex",
    justifyContent:"center",
    overflow:"hidden",
    "&:hover": {
        cursor: "pointer",
        boxShadow: theme.shadows[hoverShadow],
        borderColor: 'white',
        opacity: 1,
    }
}));

const CurrentThumb = styled(Thumb) ({
    borderColor:"white"
});


export const MapStyleSelector = () => {
    const { setBaseMap, baseMap: currentBaseMap } = useContext(MapContext);
    const thumbOptions: Array<BaseMapStyle> = ["dark-gray-vector", 'topo-vector', 'hybrid'];
    const [thumbOptionsVisible, setThumbOptionsVisible] = useState(false);

    return (
        <MapStyleSelectorWrapper spacing={0.2} onMouseOver={() => setThumbOptionsVisible(true)} onClick={() => setThumbOptionsVisible(!thumbOptionsVisible)} onMouseLeave={() => setThumbOptionsVisible(false)}>
            {thumbOptionsVisible && thumbOptions.filter(baseMap => baseMap !== currentBaseMap).map((baseMap: BaseMapStyle) => (
                <Thumb key={baseMap} onClick={() => setBaseMap(baseMap)}>
                    <img src={require(`@/assets/images/map-thumbnails/${baseMap}-map.png`)} alt={baseMap}/>
                </Thumb>
            ))}
         
            <CurrentThumb elevation={4}>
                <img src={require(`@/assets/images/map-thumbnails/${currentBaseMap}-map.png`)} alt={currentBaseMap}/>
            </CurrentThumb>
        </MapStyleSelectorWrapper>
    )
}