import { EntityTypesContext } from "@/contexts/entity-types-context";
import { MapContext } from "@/contexts/map-context"
import { AirplanemodeActive, ExpandMore, FlightTakeoff, Home, ToggleOff, ToggleOn } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Paper, Stack, Typography, styled } from "@mui/material";
import { useContext, useState } from "react";

import ReactLoading from 'react-loading';

export const FilterEntitiesPanelDiv = styled(Accordion)({
    backgroundColor: '#05233b',
    color: 'white',
    border: '1px solid #103d6a',
    // position: 'absolute',
    // top: '20px',
    // left: '10px',
    width:"300px",
    borderRadius: '5px',
    '&:hover': {
        // backgroundColor: '#efefef',
        cursor: 'pointer',
        border: '1px solid #dedede',
    },
});

interface IEntityItemProps {
    itemLabel: string,
    itemValue: any
}


export const EntityItem: React.FC<IEntityItemProps> = ({itemLabel, itemValue}: IEntityItemProps) => {
    return(
        <Stack direction='row' alignItems='center' justifyContent={'space-between'}>
            <Typography sx={{fontWeight:'bold', fontSize: "14px"}}>{itemLabel}</Typography>
            <Typography sx={{fontSize: "14px"}}>{itemValue}</Typography>
        </Stack>
    )
}




export const FilterEntitiesPanel = () => {
    const { center, gotoLocation, focusedObject } = useContext(MapContext);
    const [expanded, setExpanded] = useState<boolean>(false);
    const {isPilotModeEnabled, enablePilotMode, disablePilotMode} = useContext(MapContext);
    const {entityTypes, toggleEntityTypeVisibility, isBusy} = useContext(EntityTypesContext);
    
    return (
        <FilterEntitiesPanelDiv onChange={() => setExpanded(!expanded)} expanded={expanded}>
             <AccordionSummary expandIcon={<ExpandMore sx={{color: 'white'}}/>} sx={{color: 'white'}}>
                <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px">
                    <AirplanemodeActive sx={{width: '20px'}}/> 
                    <Typography sx={{fontSize: '14px' , fontWeight:'bold'}}>Entities</Typography>
                </Stack>
                
            </AccordionSummary>
            <AccordionDetails>
                {isBusy ? <ReactLoading type={'bubbles'} color={'#0f68ac'} height={"100px"} width={"100px"} /> : 
                
                entityTypes && entityTypes.length > 0 && entityTypes.map((entityType, index) => (
                        <EntityItem key={`entity-item-${index}`} itemLabel={entityType.title} itemValue={(<Checkbox checked={entityType.isVisible} onChange={() => toggleEntityTypeVisibility(entityType.id)}/>)} />
                    ))
                }
              
            </AccordionDetails>
        </FilterEntitiesPanelDiv>
    )
}