
import { RouteSMSNotificationsContext } from "@/contexts/route-sms-notifications-context";
import { Adjust, ExpandMore, Home, RadioButtonChecked, RadioButtonUnchecked, TripOrigin } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Input, MenuItem, Select, Stack, Typography, styled } from "@mui/material";
import { useContext, useState } from "react";
// import {useDistanceRings} from '@/hooks/use-distance-rings';
import ReactLoading from 'react-loading';


export const SMSNotificationPanel = styled(Accordion)({
    backgroundColor: '#05233b',
    color: 'white',
    border: '1px solid #103d6a',
    // position: 'absolute',
    // top: '250px',
    // left: '10px',
    width:"300px",
    borderRadius: '5px',
    '&:hover': {
        // backgroundColor: '#efefef',
        cursor: 'pointer',
        border: '1px solid #dedede',
    },
});

export const InsertExportRingsField = styled(Stack)({
    justifyContent:'space-between',
    alignItems: "cemter",
});

export const RouteSMSNotificationsPanel = () => {
   
    const [expanded, setExpanded] = useState<boolean>(false);
    const [numberOfMins, setNumberOfMins] = useState<number>(0);
    const [selectedRoute, setSelectedRoute] = useState<any>("");
    const [selectedSMSTemplate, setSelectedSMSTemplate] = useState<any>("");
    const {routesList, SMSNotify, isBusy, smsTemplatesList} = useContext(RouteSMSNotificationsContext);
    const handleChangeSelectedRoute = (value:any) => {
        setSelectedRoute(value);
    }
    const onChangeNumOfMins = (value:number) => {
        setNumberOfMins(value);
    }
    const onConfirmRouteNotif = () => {
        if(selectedRoute && selectedRoute != ""){
            const data = {
                "route_id": selectedRoute,
                "eta_minutes": numberOfMins
            };
            
            SMSNotify(data);
            setSelectedRoute(null);
            setNumberOfMins(0);
        }
       
    }
    const handleChangeSelectedSMSTemplate = (value:any) => {
        setSelectedSMSTemplate(value);
    }
    return (
        <SMSNotificationPanel onChange={() => setExpanded(!expanded)} expanded={expanded}>
            <AccordionSummary expandIcon={<ExpandMore sx={{color:'white'}}/>} sx={{display:'flex', flexDirection: 'row', alignItems:'center', justifyContent: "space-between"}}>
            <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px" >
                    <Adjust sx={{width: '20px'}}/> 
                    <Typography sx={{fontSize: '14px', fontWeight:'bold'}}>Route Notifications</Typography>
                </Stack>
                
            </AccordionSummary>
            <AccordionDetails>
                {/* <Typography sx={{color: '#bebebe', fontSize:'14px'}}>Set route notifications</Typography> */}
                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" gap="10px">
                    <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px" >
                        {/* <TripOrigin/>  */}
                        <Typography sx={{fontSize:'14px', fontWeight:'bold'}}>Select route</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        { 
                        routesList && routesList.length > 0 ? 
                        <Select
                            labelId="routes-list"
                            id={`routes-list-select`}
                            value={selectedRoute || ""}
                            // label="Routes"
                            onChange={(e) => handleChangeSelectedRoute(e.target.value)}
                            sx={{width: "270px!important",
                                zIndex: 10010,
                                color: "white!important",
                                '.MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                  fill: "white !important",
                                }
                              }}
                              MenuProps={{
                                style: {zIndex: 35001}
                            }}
                        >
                            <MenuItem value="" disabled>
                                <em>------</em>
                            </MenuItem>
                            {routesList.map((n:any, index:any) => (
                                <MenuItem key={`route-list-entry-${index}`}  value={n.id} >{n.title}</MenuItem>
                            ))}
                        </Select> : <p style={{color: "white"}}>No routes available</p>}
                    </Stack>
                </Stack>
                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" gap="10px" sx={{margin: "20px 0"}}>
                    <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px" >
                        {/* <TripOrigin/>  */}
                        <Typography sx={{fontSize:'14px', fontWeight:'bold'}}>Select SMS template</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        { 
                        smsTemplatesList && smsTemplatesList.length > 0 ? 
                        <Select
                            labelId="sms-templates-list"
                            id={`sms-templates-list-select`}
                            value={selectedSMSTemplate || ""}
                            // label="Routes"
                            onChange={(e) => handleChangeSelectedSMSTemplate(e.target.value)}
                            sx={{width: "270px!important",
                                zIndex: 10010,
                                color: "white!important",
                                '.MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                  fill: "white !important",
                                }
                              }}
                              MenuProps={{
                                style: {zIndex: 35001}
                            }}
                        >
                            <MenuItem value="" disabled>
                                <em>----</em>
                            </MenuItem>
                            {smsTemplatesList.map((n:any, index:any) => (
                                <MenuItem key={`route-list-entry-${index}`}  value={n.id} >{n.title}</MenuItem>
                            ))}
                        </Select> : <p style={{color: "white"}}>No sms templates available</p>}
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop: "10px"}}>
                    <Stack direction='row' alignItems={'center'} justifyContent={'flex-start'} gap="5px" >
                        <Typography sx={{fontSize:'14px', fontWeight:'bold'}}>Number of mins</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Input sx={{width:"60px", fontSize:'14px', color: 'white'}}   
                                inputProps={{
                                    style: {
                                        borderBottomColor: 'white' 
                                    }
                                }}
                                type='number' 
                                onChange={(e) => onChangeNumOfMins(parseInt(e.target.value))} 
                                value={numberOfMins}/>
                        <span style={{color: '#bebebe', fontSize:'14px'}}>min</span>
                    </Stack>
                </Stack>
               
                {isBusy ?
                    <ReactLoading type={'balls'} color={"white"} height={'20%'} width={'20%'} />
                    : <Button variant='contained' fullWidth sx={{marginTop: '20px'}} onClick={() => onConfirmRouteNotif()}>Confirm</Button>}
            </AccordionDetails>
        </SMSNotificationPanel>
    )
}