import { useState } from 'react';
import { MapContext } from '@/contexts/map-context';
import { BaseMapStyle } from '@/types';
// import { Scene } from '@esri/react-arcgis';
import { loadCss, loadModules } from 'esri-loader'; // loadModules
import { MapElement } from './map';
// import { Map as MapElement} from '@esri/react-arcgis';
import { useContext, useEffect, useRef } from 'react';
import { MapStyleSelector } from './map-style-selector';
import { Stack, styled } from '@mui/material';
import { MapRotationIndicator } from './map-rotation-indicator';
import { MapHome } from './map-home';
import { IMapObject } from "@/types";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils.js";
import { MapDrawPolygon } from './map-draw-polygon-button';
import { MapVideo } from './map-video';
import logo from '@/assets/logo/logo.png';


const MapTools = styled(Stack)({
    right: 10,
    bottom: 110,
    position: 'absolute',
    zIndex: 2,
})

loadCss();

export const MapDisplay = () => {
    const currentMap = useRef<any>(null);
    const currentView = useRef<any>(null);
    const {
        mapRotation,
        baseMap,
        center,
        targetLocation,
        setMapRotation,
        setMapRotated,
        setBounds,
    } = useContext(MapContext);

    const rotate = (rdeg: number) => {
        if (currentView.current && currentView.current.rotation !== rdeg) {
            const wasEnabled = currentView.current.constraints.rotationEnabled;
            currentView.current.constraints.rotationEnabled = true;
            currentView.current.rotation = rdeg;
            currentView.current.constraints.rotationEnabled = wasEnabled;
        }
    }

    const changeBaseMap = (basemap: BaseMapStyle) => {
        if (currentMap.current) {
            currentMap.current.basemap = basemap;
        }
    }

    useEffect(() => {
        if (mapRotation != null) {
            rotate(mapRotation);
        }
    }, [mapRotation]);

    useEffect(() => {
        changeBaseMap(baseMap);
    }, [baseMap]);

    useEffect(() => {
        if (targetLocation != null) {
            console.log(targetLocation);
            currentView.current.goTo({
                center: [
                    targetLocation?.center?.longitude,
                    targetLocation?.center?.latitude,
                ],
                ...(targetLocation.zoom && { zoom: targetLocation.zoom })
            },
            {
                ...(!targetLocation.zoom && { speedFactor: 100 })
            });
        }
    }, [targetLocation])

    const init = (newMap: any, newView: any) => {
        reactiveUtils.watch(
            () => [newView?.stationary, newView?.extent],
            ([stationary, extent]: any) => {
                setMapRotated(newView.rotation);
                if (newView.rotation !== mapRotation) {
                    setMapRotation(newView.rotation);
                }
                if (stationary) {
                    const projExtent = (webMercatorUtils.webMercatorToGeographic(extent) as any);
                    setBounds([
                        [
                            projExtent.ymin,
                            projExtent.xmin,
                        ],
                        [
                            projExtent.ymax,
                            projExtent.xmax,
                        ],
                    ])
                }
            }
        )

    }

    return (
        <div>
            <MapElement
                // style={{height:"100vh", width: "100vw"}}
                onLoad={(map, view) => {
                    currentView.current = view;
                    currentMap.current = map;
                    init(map, view)
                }
                }
                viewProperties={{ center }}
                mapProperties={{
                    basemap: 'hybrid',
                    // sliderPosition: "bottom-right",
                }}
            />
             <Stack height={"50px"} sx={{position: 'absolute', right: '10px', top:"10px", opacity: 0.4, zIndex: "1000"}}>
                <img src={logo} alt="logo"/>
            </Stack>
            <MapTools spacing={1}>
                <MapVideo link={'https://watch.unleashlive.com/firetech'} label="Drone 401"/>
                <MapVideo link={'https://stream.fliight.online/'} label='Drone 402'/>
                <MapHome />
                <MapRotationIndicator />
                {/* <MapDrawPolygon/> */}
            </MapTools>

            <MapStyleSelector />
        </div>
    )
}