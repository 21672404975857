import { MapContext } from "@/contexts/map-context"
import { Home, VideoCallOutlined, VideoCallRounded } from "@mui/icons-material";
import { IconButton, Tooltip, styled } from "@mui/material";
import { useContext } from "react"

export const MapVideoButton = styled(IconButton)({
    backgroundColor: 'white',
    color: '#bebebe',
    border: '2px solid #cecece',
    
    '&:hover': {
        backgroundColor: '#efefef',
        border: '2px solid #dedede',
    },
});

interface IMapVideoDesc {
    link:string,
    label:string
}



export const MapVideo: React.FC<IMapVideoDesc> = ({link, label} : IMapVideoDesc) => {

    return (
        <a href={link} target="_blank">
            <Tooltip title={label} placement="top-start">
                <MapVideoButton>
                    <VideoCallRounded />
                </MapVideoButton>
            </Tooltip>
        </a>
    )
}