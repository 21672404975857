import { HomePage } from '@/pages/home-page';
import { MapDisplay } from '@/components/map-display/map-display';
import MapContextProvider from './contexts/map-context';
import { DistanceRingsPanel } from './components/panels/distance-rings-panel/distance-rings-panel';
import { TelemetryPanel } from './components/panels/telemetry-panel/telemetry-panel';
import FlightsContextProvider from './contexts/flights-context';
import InterfaceContextProvider from './contexts/interface-context';
import AuthContextProvider from './contexts/auth-context';
import ConfigContextProvider from './contexts/config-context';
import GeometryLayersContextProvider from './contexts/geometry-layers-context';
import EntityTypesContextProvider from './contexts/entity-types-context';
import RouteSMSNotificationsContextProvider from './contexts/route-sms-notifications-context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Router, Routes, useLocation } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import { MissionPlanningPage } from './pages/mission-planning';

console.warn = () => {};

function App() {
  const location = useLocation();
  console.log(location);
  return (
    <div className="App">
      <ConfigContextProvider>
       
      <AuthContextProvider>
        <InterfaceContextProvider>
          <RouteSMSNotificationsContextProvider>
            <EntityTypesContextProvider>
              <GeometryLayersContextProvider>
                <FlightsContextProvider>
                    <MapContextProvider>

                      <Routes location={location} key={location.pathname}>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/mission-planning" element={<MissionPlanningPage/>}/>
                      </Routes>
                      
                      <MapDisplay/>
                      <ToastContainer />
                    </MapContextProvider>
                    
                </FlightsContextProvider>
              </GeometryLayersContextProvider>
            </EntityTypesContextProvider>
          </RouteSMSNotificationsContextProvider>
        </InterfaceContextProvider>
      </AuthContextProvider>
      </ConfigContextProvider>
    </div>
  );
}

export default App;
