import { ReactNode, createContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as mqtt from "mqtt";
import { useConfig } from "@/hooks/use-config";
import { useApi } from "@/hooks/use-api";

import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer.js";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer.js";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol.js";

function hexToRgba(hex: string) {
	let code = hex;

	// fix 3 and 4 letter codes
	if (code.length == 3 || code.length == 4)
		code = code.match(/./g)?.reduce(function (i: any, e: any) {
			return i + e + e;
		}, "");
	// convert to int from hex
	let codePairs = code.match(/.{1,2}/g)?.map(function (e: any) {
		return parseInt(e, 16);
	});

	if (codePairs?.length == 4)
		codePairs[3] = +(codePairs[3] / 255).toPrecision(3);
	else if (codePairs != null) { codePairs[3] = 1.0 };

	return codePairs;
}

interface IGeometryLayersContext {
    geometryLayers: any[],
    fetchGeometryLayers: () => void,
    toggleLayerVisibility: (_:any) => void,
    isBusy: boolean,
    
};

export const GeometryLayersContext = createContext<IGeometryLayersContext>({
    geometryLayers: [],
    fetchGeometryLayers: () => {},
    toggleLayerVisibility: (_:any) => {},
    isBusy: false,
});

interface IContextProps {
    children?: ReactNode,
}

const GeometryLayersContextProvider = ({ children }: IContextProps) => {
    const [geometryLayers, setGeometryLayers] = useState<any[]>([]);
    const { fetchAll } = useApi();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const toggleLayerVisibility = (id: any) => {
        const layer = geometryLayers.find(el => el.id === id);
        layer.isVisible = !layer.isVisible;
        setGeometryLayers([...geometryLayers]);
    }

    const fetchGeometryLayers = async () => {
        console.log('Fetching geometry layers');
        setIsBusy(true);
        try {
            const response = await fetchAll('geometry-layers', {});
            console.log(response);
            setGeometryLayers(response.data.map((layer: any) => ({
                id: layer.id,
                title: layer.title,
                isVisible: geometryLayers.find(el => el.id === layer.id) ? geometryLayers.find(el => el.id === layer.id).isVisible : true,
                obj: new GeoJSONLayer({ 
                url: URL.createObjectURL(new Blob([layer.geojson], {
                    type: "application/json"
                  })),
                renderer: new SimpleRenderer({
                 symbol : new SimpleFillSymbol({
                     color : hexToRgba(layer.fill_color.slice(1)), 
                     outline : { 
                         color : hexToRgba(layer.outline_color.slice(1)),
                         width : 2
                     }
                 })
             })})
            })))
        } catch (err) {
            console.log(err);
        }
        setIsBusy(false);
    }

    useEffect(() => {
        fetchGeometryLayers();
    }, []);

    return (
        <GeometryLayersContext.Provider value={{ fetchGeometryLayers, geometryLayers, toggleLayerVisibility, isBusy }}>
            {children}
        </GeometryLayersContext.Provider>
    )
}

export default GeometryLayersContextProvider;